import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function CharacterCard({image,name,selected,changeCharacter}) {
    if(selected){
        return (
            <div class="block cursor-pointer max-w-xs md:max-w-sm p-12 bg-primary-400 border border-primary-400 rounded-lg shadow hover:bg-primary-500 dark:bg-primary-400 dark:border-primary-400 dark:hover:bg-primary-300">
                <img src={image} />
                <h5 class="my-4 text-2xl text-center font-bold tracking-tight text-white dark:text-white">{name}</h5>
            </div>
        )
    }
    return (
        <div onClick={()=>changeCharacter(name)} class="block cursor-pointer max-w-xs md:max-w-sm p-12 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img src={image} />
            <h5 class="my-4 text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">{name}</h5>
        </div>
    )
}
