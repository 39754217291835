import React from 'react'
import Navbar from '../Components/Navbar'
import AudienceCard from '../Components/Audience/AudienceCard'

const audienceOptions = [
  {
    path: "student-options",
    title: "High-School Student",
    image: "https://i.postimg.cc/59vZx5Gc/2.png",
    subtitle: "I want help with my academic studies with AI tools like Chat with book, Chat with video, Learn by Teaching"
  },
  {
    path: "parent-options",
    title: "Parent",
    image: "https://i.postimg.cc/CLg9dBTR/1.png",
    subtitle: "I want to create custom curriculum for my child and teach them important concepts with the help of animated agents and dynamic content"
  }
]

const Audience = () => {
  return (
    <>
      <Navbar />
      <main className="pt-20 md:mt-10 pb-10 md:pb-0 w-full min-h-screen flex flex-col items-center justify-center bg-white dark:bg-gray-900">
        <h2 className="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">
          I want to start as ...
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-24">
          {audienceOptions.map((option) => (
            <AudienceCard key={option.path} {...option} />
          ))}
        </div>
      </main>
    </>
  )
}

export default Audience