import React from 'react'

export default function GenderCard({title,genderSetter,active}) {
    if(active && title=="Boy"){
        return (
            <div onClick={()=>genderSetter(title)} class="flex flex-col items-center justify-center max-w-sm px-24 py-4 bg-primary-400 border border-primary-400 rounded-lg shadow hover:bg-primary-500 dark:bg-primary-400 dark:border-primary-400 dark:hover:bg-primary-300">
                <h5 class="text-xl md:text-4xl font-bold tracking-tight text-white">{title}</h5>
            </div>
          )
    }
    if(active && title=="Girl"){
        return (
            <div onClick={()=>genderSetter(title)} class="flex flex-col items-center justify-center max-w-sm px-24 py-4 bg-pink-400 border border-pink-400 rounded-lg shadow hover:bg-pink-500 dark:bg-pink-400 dark:border-pink-400 dark:hover:bg-pink-300">
                <h5 class="text-xl md:text-4xl font-bold tracking-tight text-white">{title}</h5>
            </div>
          )
    }
  return (
    <div onClick={()=>genderSetter(title)} class="flex flex-col items-center justify-center max-w-sm px-24 py-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="text-xl md:text-4xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
    </div>
  )
}
