import React from 'react'

import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import { useNavigate } from 'react-router-dom'
import TeamMember from '../Components/Home/TeamMember'
import TestimonialCard from '../Components/Home/TestimonialCard'

export default function Home() {
    const navigator=useNavigate()
    return (
        <div className="row ">
        <Navbar />
        <div class="bg-white dark:bg-gray-900">
            <div class="gap-8 min-h-screen items-center flex flex-col py-8 px-4 mx-auto max-w-screen-xl xl:gap-16  sm:py-16 lg:px-6">
                <div class="mt-24 flex flex-col items-center justify-center">
                    <h2 class="mb-4 text-center md:text-center text-4xl md:text-8xl tracking-tight font-extrabold text-gray-900 dark:text-white">  <span class="text-primary-600 dark:text-primary-500">Next Generation </span> educational platform for <span class="text-primary-600 dark:text-primary-500">young</span> learners</h2>                   
                   
                    <p class="mb-6 text-center md:text-center  font-light text-gray-500 md:text-xl dark:text-gray-400">StudyGazelle is the next generation of edtech platform with dynamic content and animated videos to keep your child occupied with something that helps them learn things. Teach your children whatever you want. Build your own curriculumn</p>
                   
                    <button onClick={()=>navigator("/audience")} class="inline-flex text-center items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2.5 dark:focus:ring-primary-900" >
                        Get started
                        <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                 
                </div>
                <img class="w-2/3 hidden dark:block" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup-dark.svg" alt="dashboard " />
                <img class="w-full block dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg" alt="dashboard " />
            </div>
        </div>
        <div class="bg-gray-200 dark:bg-slate-700">
            <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <img class="w-full dark:hidden" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup.svg" alt="dashboard " />
                <img class="w-full hidden dark:block" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/cta/cta-dashboard-mockup-dark.svg" alt="dashboard " />
                <div class="mt-4 md:mt-0">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">We teach and connect you with companies</h2>
                    <p class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">You can follow our learning tracks online and offline to get placed into companies</p>
                  
                </div>
            </div>
        </div>
        <div class="max-w-screen px-4 py-8 bg-primary-200  mx-auto text-center lg:py-16 lg:px-6 dark:bg-primary-700">
           <dl class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-3 dark:text-white">
                <div class="flex flex-col items-center justify-center">
                    <dt class="mb-2 text-3xl md:text-4xl font-extrabold">4+</dt>
                    <dd class="font-light text-gray-500 dark:text-gray-400">Course Tracks</dd>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <dt class="mb-2 text-3xl md:text-4xl font-extrabold">525</dt>
                    <dd class="font-light text-gray-500 dark:text-gray-400">Reached</dd>
                </div>
                <div class="flex flex-col items-center justify-center">
                    <dt class="mb-2 text-3xl md:text-4xl font-extrabold">7+</dt>
                    <dd class="font-light text-gray-500 dark:text-gray-400">Organizations</dd>
                </div>
            </dl>
         
        </div>

        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Testimonials</h2>
                    <p class="mb-8 font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Listen about us from our users themselves</p>
                </div>
              
                <div class="grid mb-8 lg:mb-12 lg:grid-cols-2">
                
                    <TestimonialCard data={{
                        text:"The AI-powered platform has completely transformed the way I learn. As a college student juggling multiple courses, I often found it challenging to manage my study schedule. But with this platform, I receive personalized recommendations tailored to my strengths and weaknesses. The AI adapts as I progress, offering targeted practice and explaining concepts in ways that resonate with me. It's like having a tutor available 24/7, ensuring I stay on track. My grades have improved significantly, and studying has become more efficient and enjoyable!",
                        name:"Samin Ahsan",
                        location:"Student | Dhaka, Bangladesh",
                        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPUACZKGMPhENSYu4bXzOg75pkfFSjB2ttDg&s"
                    }}/>
                    <TestimonialCard data={{
                        text:"As an educator, I was initially skeptical about integrating AI into the classroom. However, this platform has proven to be an invaluable tool. The AI provides real-time insights into each student’s progress, allowing me to tailor lessons more effectively. It identifies struggling students early and offers adaptive resources to help them catch up. Students are more engaged because they receive content that matches their learning pace. The platform has lightened my workload while enhancing the overall learning experience in ways I never imagined!",
                        name:"Ayesha Rahman",
                        location:"Educator | Dhaka, Bangladesh",
                        image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS09STFPMGaO3g79fR43thLUpetOCFA0clZKw&s"
                    }}/>
                   

                </div>
                {/* <div class="text-center">
                    <a href="#" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Show more...</a>
                </div> */}
            </div>
        </section>

        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Team</h2>
                    
                    <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Meet our dynamic team who are here to help you at each step of your journey</p>
                    
                </div>
                <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                    <TeamMember data={{
                        name:"Salman Sayeed",
                        title:"Chief Executive Officer",
                        subtitle:"Founder and core developer of Studygazelle",
                        image:"https://media.licdn.com/dms/image/D5603AQE80tXuogBWsQ/profile-displayphoto-shrink_800_800/0/1709538069121?e=1726099200&v=beta&t=c0QnZuR8GyAjqfEqsQ_TRtouuDiWx9DFK2_CA0BbfeE",
                        portfolio:"https://salmansayeed.com"
                    }}/>
                    
                 

                </div>
            </div>
        </section>

        <Footer />
    </div>

    )
}
