import React, { useState,useRef,useEffect } from "react";
import {IoSendSharp} from 'react-icons/io5'
import {BiVideo} from 'react-icons/bi'
import { FaArrowLeft } from "react-icons/fa";
import AssistantChatLong from "../../Components/Chatter/AssistantChatLong";
import UserChatLong from "../../Components/Chatter/UserChatLong";
import Loader from "../../Components/Loader";
import AssistantRag from "../../Components/Chatter/AssistantRag";
import { BACKEND_ENDPOINT } from "../../Data/APIEndpoints";
import axios from 'axios'
// https://i.postimg.cc/c4qT8QR9/12.png
// https://i.postimg.cc/B6kppFdv/13.png
// https://i.postimg.cc/d3CBx1Yj/14.png
// https://i.postimg.cc/506p8gKG/15.png
// https://i.postimg.cc/mrd8cJjC/16.png
// https://i.postimg.cc/8kft5y6f/17.png
// https://i.postimg.cc/tJnP4mvq/18.png
export default function ChatVideos() {
    const bottomRef=useRef()
    const [started,setStarted]=useState(false)
    const [model,setModel]=useState("gemma")
    const [currentUserChat,setCurrentUserChat]=useState(null)
    const [generatingChat,setGeneratingChat]=useState(false)

    const [loadingTranscription,setLoadingTranscription]=useState(false)
    const [currentYtVideo,setCurrentYtVideo]=useState(null)
    const [transcription,setTranscription]=useState(null)
    const [submittedLink,setSubmittedLink]=useState(false)
    const [transcriptionComplete,setTranscriptionComplete]=useState(false)
    const [conversation,setConversation]=useState([
      {   
        role:"system",
        content:"You are an agent that helps users learn things. The user may ask you questions or tell you to explain certain things. Try to answer appropriately. If you give empty answers, you will be penalized 100 dollars. If you answer in markdown you will get 100 dollar reward"
      },
      {   
        role:"assistant",
        content:"Hello there I'm your assistant here to help you with anythng that you need. Please feel free to ask me any general questions about anything that you might want to know. I am here to assist you in any way that I can.I understand that I cannot give empty answers, so I will always make sure to give you a helpful response or redirect you to a source that can help you.Let me know how I can assist you today!"
      }
  ])
    const generateTranscription=async()=>{
        if(currentYtVideo==null){
            alert("Enter a video ID first!")
            return
        }
        setSubmittedLink(true)
        setTranscriptionComplete(false)
        setTranscription(null)
        const currLink=`https://www.youtube.com/watch?v=${currentYtVideo}`
        console.log(currLink)
        setLoadingTranscription(true)
        const res=await axios.post(BACKEND_ENDPOINT+"/yt-transcription",{
            url:currLink
        })
        setLoadingTranscription(false)
        setTranscriptionComplete(true)
        setTranscription(res.data)
        console.log(res.data)

    }

    const sendRAGQuery=async()=>{
        setCurrentUserChat("")
        if(!transcriptionComplete){
          alert("You need to submit youtube video ID to continue")
          return
        }
        setGeneratingChat(true)
        const userConvBody={
          role:"user",
          content:currentUserChat
        }
        setConversation((conversation)=>[...conversation,userConvBody])
        const res=await axios.post(BACKEND_ENDPOINT+"/rag",{
          "query":currentUserChat
        })
        console.log(res.data)
        const assistantConv={
          role:"assistant-rag",
          content:res.data
        }
        setConversation((conversation)=>[...conversation,assistantConv])
        setGeneratingChat(false)
      }
  
      useEffect(()=>{
        if(bottomRef.current){
          bottomRef.current.scrollIntoView()
        }
      },[conversation])
  return (
    <div>

        {/**CUSTOM NAVBAR */}
        <div className="flex z-10 justify-center w-screen h-20 bg-gray-100 dark:bg-slate-800 fixed top-0" style={{ alignItems: "center" }}>
            <img alt="icon" width="30px" height="30px" className="mr-2" src="https://i.postimg.cc/L8T1V5pD/image.png" onClick={() => navigator("/")}/>
            <h2 className="text-xl font-bold text-black font-title dark:text-gray-100">Conversate w Videos</h2>
        </div>
        <button type="button" onClick={()=>window.history.back()} class="z-20 fixed left-2 md:left-20 top-6 bg-gray-400 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FaArrowLeft size={24} /></button>
      <div className="w-screen min-h-screen flex flex-col items-center justify-center bg-white p-4 md:p-24 bg-pink-200 dark:bg-gray-900">

        {/** BUTTONS 
        <div class="inline-flex rounded-md shadow-sm mb-6" role="group">
          {model=="gemma" ? <button type="button" class="px-4 py-2 text-sm flex items-center font-medium text-gray-900 bg-primary-400 text-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">Gemma : 2B</button>:<button onClick={()=>setModel("gemma")} type="button" class="px-4 py-2 text-sm flex items-center font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">Gemma : 2B</button>}
          {model=="gpt" ? <button type="button" class="px-4 py-2 text-sm flex items-center font-medium text-gray-900 bg-primary-400 text-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">GPT 4</button>:<button onClick={()=>setModel("gpt")} type="button" class="px-4 py-2 text-sm flex items-center font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">GPT 4</button>}
          
        </div>*/}
        <div className="flex flex-col-reverse md:flex-row w-full justify-between">
            {/**CHATTER */}
            <div href="#" class="relative mt-4 md:mt-0 w-full md:w-2/5 min-h-[700px] bg-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            {/**CHATS */}
            <div className="absolute pb-20 top-0 flex p-6 flex-col w-full max-h-[700px] overflow-y-scroll">
                
                {conversation.map(a=>{
                    if(a.role=="assistant"){
                        return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                    }
                    if(a.role=="assistant-rag"){
                        return (<AssistantRag data={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                    }
                    else if(a.role=="user"){
                        return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                    }
                })}
                {generatingChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
                <div ref={bottomRef}></div>
            </div>
            {/**INPUT */}
              <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
                <textarea onKeyDown={(e)=>{
                  if(e.key==="Enter"){
                    e.preventDefault()
                    sendRAGQuery()
                  }
                }} type="text" rows={1} class=" block w-full md:w-4/5 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
                <div className="flex">
                  <button onClick={sendRAGQuery} class="h-full mx-1 text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><IoSendSharp /></button>
                </div>
              </div>
            </div>
            {/**VIDEO BLOCK*/}
            <div href="#" class="relative p-2 flex flex-col items-center justify-start w-full md:w-3/5 min-h-[700px] bg-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            {/**Youtube Video */}

            {!submittedLink &&  <div class="flex items-center justify-center h-full w-3/5">
                <label class="flex flex-col mt-24 md:mt-0 items-center justify-center w-full h-2/3 border-2 rounded-lg border-gray-300 border rounded-lg bg-gray-50 dark:bg-gray-700  dark:border-gray-600 ">
                  <div class="flex flex-col items-center justify-center  ">
                    <img width={350} src="https://i.postimg.cc/XNKvRbcy/apex.png"/>
                    <p class="mb-2 text-md flex flex-col ">
                        <span className="font-semibold">Paste the <span class="text-center font-bold text-orange-500">video ID </span> </span>
                      <span className="text-xs text-center">of your youtube video</span>
                    </p>
                    <p class="text-sm ">Example : HyWYpM_S-2c</p>
                  </div>
                </label>
              </div>}
            {transcriptionComplete && <div className="flex flex-col w-full max-h-[350px] items-center justify-center">
                <iframe className="w-full" height={350} src={`https://www.youtube.com/embed/${currentYtVideo}?autoplay=0&mute=0`} allowFullScreen > </iframe>
            </div>}

            {/** Transcription */}
            {loadingTranscription && <Loader text="Generating Transcription for your video...."/>}
            <div className="w-full px-2 max-h-[300px] overflow-y-scroll">
                
                {transcriptionComplete && 
                    transcription.map(a=>
                        <div className="flex flex-col ">
                        <a className="cursor pointer text-orange-500 text-sm pt-1" href={a.link} target="_blank"><span className="font-semibold">{a.timestamp}</span></a>
                        <p className="text-sm">{a.text}</p>
                        </div>
                    ) 
                }
            </div>
            <div className="absolute -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
                <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <BiVideo className="dark:text-white"/>
                </div>
                <input type="text" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter youtube video link" value={currentYtVideo} onChange={e=>setCurrentYtVideo(e.target.value)}/>
                <button onClick={generateTranscription} type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Load</button>
                </div>
            </div>
            </div>
            {/**INPUT */}
            
        </div>
      </div>
    </div>
  );
}
