import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ClassCard({image,name,selected,changeClassroom}) {
    if(selected){
        return (<div class="block cursor-pointer max-w-xs md:max-w-sm p-10 bg-primary-400 border border-primary-400 rounded-lg shadow hover:bg-primary-500 dark:bg-primary-400 dark:border-primary-400 dark:hover:bg-primary-300"><img src={image} /></div>)
    }
    return (
        <div onClick={()=>changeClassroom(name)} class="block cursor-pointer max-w-xs md:max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img src={image} />
        </div>
    )
}
