import React, { useState } from "react";
import axios from 'axios'
import { MdClose, MdDelete } from "react-icons/md";


export default function CreateFlashcardModal({closeModal }) {
    const [formData, setFormData] = useState({});
    const [generating,setGenerating]=useState(false)
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <div className="relative bg-white m-4 rounded-md shadow-lg max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Create Flashcards</h2>
                <h2 className="text-sm">Choose a topic name and upload a file to create brand new flashcards</h2>
            </div>
            {!generating && <div className="space-y-4 p-12 ">
                <div>
                <label className="block text-sm text-gray-700 font-semibold">
                    Topic Title
                </label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Topic Title"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
                
                </div>
                <div>
                    <label className="block text-sm text-gray-700 font-semibold">
                    Upload Document(Optional)
                </label>
                <input
                    type="file"
                    name="file"
                    value={formData.file}
                    onChange={handleChange}
                    placeholder="Document Upload"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
                </div>    
            </div>}
            <div className="flex justify-end space-x-2 mt-6 rounded-b-md bg-emerald-700 py-4 px-4">
                {/* <button onClick={closeModal} className="text-sm font-semibold bg-red-500 text-white px-4 py-2 rounded">Cancel</button> */}
                <button className="text-sm font-semibold bg-white hover:bg-gray-200 text-emerald-700 px-4 py-2 rounded">Create</button>
            </div>
        </div>
      </div>
    );
  }