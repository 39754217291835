import React,{useEffect, useRef, useState} from 'react'
import { FaMinus } from "react-icons/fa6";
import {motion} from 'framer-motion'
import { CgAdd, CgClose } from 'react-icons/cg';
import { VscServerProcess } from "react-icons/vsc";

import { BiBook, BiCopy, BiGroup, BiLink, BiSave, BiUpload } from 'react-icons/bi';
import {  GrUser } from 'react-icons/gr';
import { RiUploadCloud2Line } from "react-icons/ri";
import MyChatter from '../../Components/CollaborativeClassroom/MyChatter';
import GroupChatter from '../../Components/CollaborativeClassroom/GroupChatter';
import { BACKEND_ENDPOINT } from '../../Data/APIEndpoints';
import axios from 'axios'

// PDF Renderer Elements Import
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; //npm install pdfjs-dist@3.4.120
import ParticipantCard from '../../Components/CollaborativeClassroom/ParticipantCard';
import MyNotes from '../../Components/CollaborativeClassroom/MyNotes';
import GroupNotes from '../../Components/CollaborativeClassroom/GroupNotes';
import CCBooksModal from '../../Components/CollaborativeClassroom/CCBooksModal';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import { createSessionUser, getSessionUsers } from '../../API/SessionUser';
import { createSessionMessage, getMessagesBySession } from '../../API/SessionMessage';
import { supabase } from '../../supabaseClient';
import { getSessionNote, updateSessionNote } from '../../API/SessionNote';

export default function CollaborativeClassroom() {
    const params=useParams()
    const session_id= params.id

    const [userName,setUserName]=useState(localStorage.getItem("sal-session-username") || null)
    const [userDesignation,setUserDesignation] = useState(localStorage.getItem("sal-session-userdesignation") || null)

    const [theme,setTheme]=useState('light')
    const [showAddMenu,setShowAddMenu]=useState(false)
    const [showBookMenu,setShowBookMenu]=useState(false)
    const [showParticipants,setShowParticipants]=useState(false)

    const [uploadAnother,setUploadAnother]=useState(false)
    const [uploadedFiles,setUploadedFiles]=useState([])
    const [pdfUrls,setPdfUrls]=useState([])
    const [selectedIndex,setSelectedIndex]=useState(-1)
    const [uploadSuccess,setUploadSuccess]=useState(false)
    const [ingested,setIngested]=useState(false)

    const [updatingNote, setUpdatingNote] = useState(true)
   
    const [myChatterActive,setMyChatterActive]=useState(true)
    const [groupChatterActive,setGroupChatterActive]=useState(true)
    const [myNotesActive,setMyNotesActive]=useState(true)
    const [groupNotesActive,setGroupNotesActive]=useState(true)

    const [myNote,setMyNote]=useState("## HELLO WORLD")
    const [groupNote,setGroupNote]=useState("")

    const [groupMessageList,setGroupMessageList]=useState([])
    const [myMessageList,setMyMessageList]=useState([])

    const [users,setUsers] = useState([])
    const [loadingUsers,setLoadingUsers] = useState(true)

    const sendDocuments=async()=>{
        try{
            uploadedFiles.map(async(a,idx)=>{
                if(uploadedFiles[idx]==null){
                    alert("Please select a PDF file first!")
                    return
                  }
                  const formData=new FormData()
                  formData.append('files[]',uploadedFiles[idx])
                  //console.log(formData)
                  const res=await axios.post(BACKEND_ENDPOINT+"/file-upload",formData)
                  //console.log(res.data.status)
                  if(res.data.status==="success"){
                    
                  }else{
                    alert(`Your file titled ${uploadedFiles[idx]} was not uploaded. Try with a smaller file please.`)
                  }
            })
            setUploadSuccess(true)
            alert("Your file has been sent to model")
        }catch(e){
            alert("There was something wrong uploading your files.")
        }
        
        
    }
    const ingestDocuments=async()=>{
        if(!uploadSuccess){
          alert("Upload your PDF to the model first!")
          return
        }
        const res=await axios.get(BACKEND_ENDPOINT+"/ingest")
        //console.log(res.data)
        if(res.data.status==="success"){
          setIngested(true)
          alert("Ingestion Complete! You can chat now!")
        }else{
          alert("Your ingestion was not complete. Please Try again!")
        }
    }

    const getSessionMessages = async()=>{
        
        try{
            const res = await getMessagesBySession(session_id)
            console.log(res)
            setGroupMessageList(res)
        }catch(e){
            console.error(e)
        }

    }
    const getMySessionUsers = async()=>{
            
            try{
                const res = await getSessionUsers(session_id)
                //console.log(res)
                setUsers(res)
                setLoadingUsers(false)
            }catch(e){
                console.error(e)
            }
    }

    const joinSession = async()=>{
        try{
            const res = await createSessionUser({
                user_id:'e65047b8-e8a9-4b97-baed-507e98bda21c',
                user_name:userName,
                user_designation:userDesignation,
                session_id:session_id
            })
            localStorage.setItem("sal-session",session_id)
            localStorage.setItem("sal-session-username",userName)
            localStorage.setItem("sal-session-userdesignation",userDesignation)
            window.location.reload()
        }catch(e){  
            console.error(e)
        }

    }

    const sendMessage = async(data)=>{
        try{
            console.log(data)
            const res= await createSessionMessage({
                "session_id":session_id,
                "type": data.type,
                "message": data.message,

                "reply_to": data.reply_to,
                "serial": data.serial,
                "user_name":userName,
                "user_designation":userDesignation,
                "user_id":"e65047b8-e8a9-4b97-baed-507e98bda21c"
            })
            console.log(res)
        }catch(e){
            console.error(e)
        }
    }

    const minimizeMyChatter=()=>{
        setShowAddMenu(false)
        setMyChatterActive(false)
    }
    const minimizeGroupChatter=()=>{
        setShowAddMenu(false)
        setGroupChatterActive(false)
    }
    const minimizeMyNotes=()=>{
        setShowAddMenu(false)
        setMyNotesActive(false)
    }
    const minimizeGroupNotes=()=>{
        setShowAddMenu(false)
        setGroupNotesActive(false)
    }
    const getUpdatedNote = async()=>{
        
        try{
            const res = await getSessionNote(session_id)
            console.log(res)
            setGroupNote(res.content)
        }catch(e){
            console.error(e)
        }
        
    }
    const updateNote = async(data)=>{
        setUpdatingNote(true)
        setGroupNote(data);
        const res = await updateSessionNote({
            session_id:session_id,
            content:data
        })
        setGroupNote(res.content)
        setUpdatingNote(false)
    }
    useEffect(() => {
        if (!session_id) return; // Ensure session_id is defined
    
        if (localStorage.getItem('color-theme') === 'dark') {
            setTheme('dark');
        }
        getSessionMessages();
        getUpdatedNote()
        getMySessionUsers()
        const userSubscription = supabase
            .channel("session_user")
            .on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'session_user', filter: `session_id=eq.${session_id}` },
                (payload) => {
                    console.log('New user added:', payload);
                    getSessionUsers();
                }
            )
            .subscribe((status) => {
                console.log('User Subscription status:', status);
            });
        const messageSubscription = supabase
            .channel("session_message")
            .on(
                'postgres_changes',
                { event: 'INSERT', schema: 'public', table: 'session_message', filter: `session_id=eq.${session_id}` },
                (payload) => {
                    console.log('New message inserted:', payload);
                    getSessionMessages();
                }
            )
            .subscribe((status) => {
                console.log('Messages Subscription status:', status);
            });
        const noteSubscription = supabase
            .channel("session_note_public")
            .on(
                'postgres_changes',
                { event: 'UPDATE', schema: 'public', table: 'session_note_public', filter: `session_id=eq.${session_id}` },
                (payload) => {
                    console.log('Note updated:', payload);
                    getUpdatedNote()

                }
            )
            .subscribe((status) => {
                console.log('Notes Subscription status:', status);
            });
    
        return () => {
            supabase.removeChannel(messageSubscription);
            supabase.removeChannel(userSubscription);
            supabase.removeChannel(noteSubscription);
        };
    }, [session_id]);
    
    
    {/**NEW USER */}
    if(localStorage.getItem("sal-session")!=params.id){
        return (
        <div className='w-screen min-h-screen pt-12'>
            <Navbar/>
            <div className="w-full h-screen flex items-center justify-center bg-gray-100 dark:bg-slate-700 dark:text-gray-300 pb-12">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="w-full flex flex-col items-center m-12 p-12 py-24 max-w-7xl bg-white dark:bg-slate-800 rounded-lg shadow-lg"
                >
                    <motion.h2
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    className="text-xl md:text-2xl lg:text-3xl font-semibold text-center"
                    >
                    Enter <span className="text-primary-500">Information</span>
                    </motion.h2>

                    <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    className="flex flex-col items-start w-full space-y-2 py-2"
                    >
                    <label className="font-semibold">User Name</label>
                    <input
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-slate-700 dark:border-slate-800 dark:text-gray-100"
                        placeholder="Enter Your Name"
                    />
                    </motion.div>

                    <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    className="flex flex-col items-start w-full space-y-2 py-2"
                    >
                    <label className="font-semibold">User Designation</label>
                    <input
                        value={userDesignation}
                        onChange={(e) => setUserDesignation(e.target.value)}
                        className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-slate-700 dark:border-slate-800 dark:text-gray-100"
                        placeholder="Enter Your Designation"
                    />
                    </motion.div>

                    <motion.button
                    onClick={joinSession}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="mt-4 text-lg font-semibold px-4 py-2 rounded-md bg-primary-500 hover:bg-primary-600 text-white shadow-md dark:text-slate-800"
                    >
                    Join Session
                    </motion.button>
                </motion.div>
            </div>
        </div>
        )
    }
    {/**VALID USER */}
  return (
    <div data-color-mode={theme} className='w-screen h-screen flex items-center justify-center flex-wrap bg-blue-950 dark:bg-blue-950 pb-12'>
       
        {(!myChatterActive || !groupChatterActive || !myNotesActive || !groupNotesActive) && <motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>setShowAddMenu(!showAddMenu)} className='z-10 fixed bottom-2 right-2 rounded p-1.5 bg-primary-500 text-xl font-semibold text-white cursor-pointer hover:bg-primary-600 hover:text-gray-100'>
            <CgAdd/>
        </motion.button>}
        {<motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>{
            //alert("HELP")
            setShowBookMenu(true)}} className='z-10 fixed bottom-2 left-12 rounded p-1.5 bg-primary-500 text-xl font-semibold text-white cursor-pointer hover:bg-primary-600 hover:text-gray-100'>
            <BiBook/>
        </motion.button>}
        {<motion.button whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>setShowParticipants(!showParticipants)} className='z-10 fixed bottom-2 left-2 rounded p-1.5 bg-primary-500 text-xl font-semibold text-white cursor-pointer hover:bg-primary-600 hover:text-gray-100'>
            <BiGroup/>
        </motion.button>}
        {/** BOOK MENU */}
        <CCBooksModal open={showBookMenu} onClose={()=>setShowBookMenu(false)}/>
        {/* {showBookMenu && <div className='z-20 fixed flex flex-col items-center justify-center top-0 left-0 w-full h-full bg-green-400'>
            {(uploadedFiles[0]==null || uploadAnother) && <div class="flex items-center justify-center h-full md:w-2/3">
                <label
                    for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-2/3 border-2 border-green-300 border-dashed rounded-lg cursor-pointer bg-green-50  dark:bg-gray-700 hover:bg-green-100 dark:border-green-600 dark:hover:border-green-500 dark:hover:bg-green-600"
                >
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <RiUploadCloud2Line
                        size={32}
                        className="text-green-500 dark:text-green-400"
                    />
                    <p class="mb-2 text-sm text-green-500 dark:text-green-400">
                        <span class="font-semibold">Click to upload</span> or drag and
                        drop
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                        PDF, PPTX, CSV or TXT (MAX. 2MB)
                    </p>
                    </div>
                    <input id="dropzone-file" type="file" class="hidden" onChange={(e)=>{
                    setUploadAnother(false)
                    setUploadedFiles((uploadedFiles)=>[...uploadedFiles,e.target.files[0]])
                    setPdfUrls((pdfUrls=>[...pdfUrls,URL.createObjectURL(e.target.files[0])]))
                    }}/>
                </label>
            </div>}
            {pdfUrls[selectedIndex]!=null &&<div className="w-4/5 max-h-[300px] md:max-h-[700px] overflow-y-scroll no-scrollbar">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdfUrls[selectedIndex]} />
                </Worker> 
            </div>}
            <div className='w-2/3 flex justify-center flex-wrap py-4'>
                {pdfUrls.map((a,idx)=>(
                    <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>setSelectedIndex(idx)} className={selectedIndex===idx?`px-4 py-2 m-2 text-sm font-semibold  text-white bg-green-500 shadow rounded cursor-pointer hover:bg-green-600`:` px-4 py-2 m-2 text-sm font-semibold bg-white shadow rounded cursor-pointer hover:bg-gray-200`}><p>Book {idx+1}</p></motion.div>
                ))}
                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>{
                    setSelectedIndex(-1)
                    setUploadAnother(true)
                }} className='p-2 m-2 flex items-center text-lg bg-white shadow rounded cursor-pointer hover:bg-gray-200'><CgAdd/></motion.div>
                
            </div>
            <div className='absolute top-4 right-4 grid grid-cols-1 gap-2'>
                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={()=>{setShowBookMenu(false)}} className='p-2 flex items-center text-lg bg-white shadow rounded cursor-pointer hover:bg-gray-200'><CgClose/></motion.div>
                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={sendDocuments} className='p-2 flex items-center text-lg bg-white shadow rounded cursor-pointer hover:bg-gray-200'><BiUpload/></motion.div>
                <motion.div whileHover={{scale:1.1}} whileTap={{scale:0.9}} onClick={ingestDocuments} className='p-2 flex items-center text-lg bg-white shadow rounded cursor-pointer hover:bg-gray-200'><VscServerProcess/></motion.div>
            </div>
            

        </div>} */}

        {/** PARTICIPANT LIST */}
        {showParticipants && <div className='z-10 fixed bottom-14 left-6 p-4 flex flex-col bg-white rounded'>
            <h2 className='text-sm py-2 font-semibold'>Current Participants</h2>
            <div onClick={()=>setShowParticipants(false)} className='absolute top-2 right-2 text-black cursor-pointer hover:text-gray-700'><CgClose/></div>
            {users.map((a,idx)=><ParticipantCard name={a.user_name} institute={a.user_designation} active={true} added={idx%2===0}/>)}
           
            <div className='relative w-full mt-6 my-2 px-2 py-1 rounded border-2 border-dotted border-gray-500'>
                <div onClick={()=>navigator.clipboard.writeText("https://studygazelle.com/group-study/invite/4512")} className='absolute top-2 right-2 text-black cursor-pointer hover:text-gray-700'><BiCopy/></div>
                <p className='flex items-center text-sm font-semibold'><BiLink/> Invite Participants</p>
                <input className='w-full text-xs' placeholder='https://studygazelle.com/group-study/invite/4512'/>
            </div>
            
        </div>}
        {/** MINIMIZED WINDOWS */}
        {showAddMenu && <div className='z-10 fixed bottom-14 right-6 p-2 flex flex-col items-end'>
            {!myChatterActive && <button onClick={()=>setMyChatterActive(true)} className='bg-white rounded-md text-sm font-semibold px-4 py-2 cursor-pointer hover:bg-gray-100 shadow-sm my-1'>My Chatter</button>}
            {!groupChatterActive && <button onClick={()=>setGroupChatterActive(true)} className='bg-white rounded-md text-sm font-semibold px-4 py-2 cursor-pointer hover:bg-gray-100 shadow-sm my-1'>Group Chatter</button>}
            {!myNotesActive && <button onClick={()=>setMyNotesActive(true)} className='bg-white rounded-md text-sm font-semibold px-4 py-2 cursor-pointer hover:bg-gray-100 shadow-sm my-1'>My Notes</button>}
            {!groupNotesActive && <button onClick={()=>setGroupNotesActive(true)} className='bg-white rounded-md text-sm font-semibold px-4 py-2 cursor-pointer hover:bg-gray-100 shadow-sm my-1'>Group Notes</button>}
            
        </div>}
        {/** WINDOWS */}
        {(myChatterActive || groupChatterActive) && <div className={(myNotesActive || groupNotesActive)?`flex w-full h-1/2`:`flex w-full h-full`}>
            {/**My Chatter */}
            {myChatterActive && <div className={groupChatterActive ? `relative w-1/2 h-full  border border-gray-200 bg-red-400 overflow-y-scroll no-scrollbar`:`relative w-full h-full  border border-gray-200 bg-red-400 overflow-y-scroll no-scrollbar`}>
                <motion.button onClick={minimizeMyChatter} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='absolute top-2 right-2 p-1 rounded-full shadow-md border border-gray-100 bg-white cursor-pointer text-xs hover:bg-gray-50'><FaMinus /></motion.button>
                <div className='w-full h-full flex flex-col'>
                    <p className='flex items-center p-2 border border-gray-200 text-white text-sm font-semibold px-4'><GrUser className='mr-2'/>My Chatter</p>
                    <MyChatter/>
                </div>
            </div>}
            {/**Group Chatter */}
            {groupChatterActive && <div className={myChatterActive ? `relative w-1/2 h-full  border border-gray-200 bg-amber-400 overflow-y-scroll no-scrollbar`:`relative w-full h-full  border border-gray-200 bg-amber-400 overflow-y-scroll no-scrollbar`}>
                <motion.button onClick={minimizeGroupChatter} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='absolute top-2 right-2 p-1 rounded-full shadow-md border border-gray-100 bg-white cursor-pointer text-xs hover:bg-gray-50'><FaMinus /></motion.button>
                <div className='w-full h-full flex flex-col'>
                    <p className='flex items-center p-2 border border-gray-200 text-white text-sm font-semibold px-4'><GrUser className='mr-2'/>Group Chatter</p>
                    <GroupChatter messageList={groupMessageList} sendMessage={sendMessage}/>
                </div>
            </div>}
        </div>}
        {(myNotesActive || groupNotesActive) && <div className={(myChatterActive || groupChatterActive)?`flex w-full h-1/2`:`flex w-full h-full`}>
            {/**My Notes */}
            {myNotesActive && <MyNotes groupNotesActive={groupNotesActive} minimizeMyNotes={minimizeMyNotes} myNote={myNote} setMyNote={setMyNote}/>}
            {/**Group Notes */}
            {groupNotesActive && <GroupNotes updating={updatingNote} myNotesActive={myNotesActive} minimizeGroupNotes={minimizeGroupNotes} groupNote={groupNote} updateGroupNote={updateNote}/>}
        </div>}
    </div>
  )
}
