import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FaSun, FaMoon } from 'react-icons/fa';

export default function Navbar() {
    const navigate = useNavigate();
    const [theme, setTheme] = useState('');
    const { scrollY } = useScroll();
    
    const opacity = useTransform(scrollY, [0, 50], [0.8, 1]);
    const boxShadow = useTransform(
        scrollY,
        [0, 50],
        ['0px 0px 0px rgba(0, 0, 0, 0)', '0px 2px 8px rgba(0, 0, 0, 0.1)']
    );

    useEffect(() => {
        const savedTheme = localStorage.getItem('color-theme');
        setTheme(savedTheme === 'light' ? 'light' : 'dark');
        document.documentElement.classList.toggle('dark', savedTheme !== 'light');
    }, []);

    const changeTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('color-theme', newTheme);
        document.documentElement.classList.toggle('dark', newTheme === 'dark');
    };

    return (
        <motion.nav 
            className="fixed top-0 left-0 right-0 flex justify-between items-center p-4 backdrop-blur-sm z-50 transition-colors duration-300 bg-white dark:bg-gray-800"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ boxShadow }}
        >
            <motion.div
                className="absolute inset-0 bg-white dark:bg-gray-800"
                style={{ opacity }}
            />
            <motion.div
                className="flex items-center cursor-pointer z-10"
                onClick={() => navigate("/")}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <img className="flex mr-2 w-12 h-12" src='https://i.postimg.cc/L8T1V5pD/image.png' alt="gazelle"/>
                <span className="text-xl font-bold text-gray-800 dark:text-white">StudyGazelle</span>
            </motion.div>

            <motion.button
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 z-10"
                onClick={changeTheme}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                {theme === 'light' ? (
                    <FaMoon className="text-gray-800" />
                ) : (
                    <FaSun className="text-yellow-400" />
                )}
            </motion.button>
        </motion.nav>
    );
}