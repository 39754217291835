import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import Flashcard from '../../Components/Flashcards/Flashcard'
import { MdAdd } from 'react-icons/md'
import { generateSpeech } from '../../Models/ClassroomAgent'
import CreateFlashcardModal from '../../Components/Flashcards/CreateFlashcardModal'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
export default function FlashCards() {
    const navigate=useNavigate()
    const [uploadDocumentModal,setUploadDocumentModal]=useState(false)
    const topicName="Reflection of light for grade 6"
    const [flashCards,setFlashCards]=useState([])
    const [generating,setGenerating]=useState(true)
    const getOllama=async()=>{
        const res=await generateSpeech(`You want to teach a student the ${topicName}. You basically create flashcards for them to memorize things faster. The flashcards that you have are generated in the following JSON format :\n
            {
            "question":"The word that you want to teach",
            "answer":"The meaning of that word"
            }\n
        Don't write anything else. Just the JSON data. Return only the JSON data in an ARRAY. Nothing else. If you return anything else you will be penalized 500 dollars.
        `,"Now generate 10 such flashcards")
        console.log(res)
        setFlashCards(JSON.parse(res))
        setGenerating(false)
    }
    const closeModal=()=>{
        setUploadDocumentModal(false)
    }
    useEffect(()=>{
        getOllama()
    },[])
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
        <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:justify-between md:p-6 p-4 bg-white'>
            <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                <FaAngleLeft/>
            </motion.button>
            <h2 className='text-md md:text-xl font-semibold ml-4 md:ml-12'>FlashCards <span className='hidden md:block text-emerald-700'>{topicName}</span></h2>
            <p className='hidden md:block text-sm font-semibold text-gray-500'>Flashcards : <span className='text-lg font-semibold text-gray-200 rounded-lg bg-gray-700 p-2'>{flashCards.length}</span></p>
        </div>
        <button onClick={()=>setUploadDocumentModal(true)} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><MdAdd className='text-xl'/>Create New</button>
        <div className='w-full max-w-7xl grid grid-cols-1 md:grid-cols-3 gap-4 mt-16 md:mt-32'>
               {!generating && flashCards.map(a=>(<Flashcard key={a.question} item={a}/>))}
               {generating && <p>Generating Flashcards...</p>}
        </div>
        {uploadDocumentModal && <CreateFlashcardModal closeModal={closeModal}/>}
    </div>
  )
}
