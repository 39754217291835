import React from 'react'

export default function BasicInformation() {
  return (
    <div className='relative w-full p-6 shadow-lg rounded-lg border'>
        <p className='absolute top-4 right-4 text-xl rounded text-gray-200 bg-emerald-700 px-2 py-1 font-semibold'>7th Grade</p>
        <h4 className='text-lg font-semibold text-emerald-700 mb-4'>Basic Information</h4>
        <div className='flex flex-col items-start'>
            <img alt="DP" className='w-16 p-2 h-16 rounded-full border-2 border-emerald-700' src="logo.png"/>
            <p className='text-sm font-semibold text-emerald-700'>Name : <span className='font-normal text-gray-700'>Salman Sayeed</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Email : <span className='font-normal text-gray-700'>salman@gmail.com</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Age : <span className='font-normal text-gray-700'>24 Years 3months 17days</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Institution : <span className='font-normal text-gray-700'>Notre Dame College</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Bio : <p className='font-normal text-gray-700'>Learning with the greatest ever platform called StudyGazelle</p></p>
        </div>
    </div>
  )
}
