import { supabase } from "../supabaseClient"
export const createSession=async(data)=>{
    const res=await supabase
    .from('session') 
    .insert([{
        "user_id":data.user_id ,
        "user_name":data.user_name,
        "user_designation":data.user_designation,
        "title": data.title,
        "type":data.type,
        "active":true
       
     }]).select();
    console.log(res)
    return res.data[0]
}
export const getSessionById=async(id)=>{
    const res=await supabase
    .from('session') 
    .where('id','eq',id)
    .select();
    return res.data
}