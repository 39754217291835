export const subjects={
    "🥼🧪Science🔬🧬" : {
        lessons:[
            "Plants and Gardening",
            "Animals and Habitats",
            "The Solar System",
            "Weather",
            "States of Matter",
            "Simple Machines",
            "The Human Body",
            "Sound and Music",
            "Colors and Light",
            "Rocks and Minerals",
            "Electricity and Circuits"
        ]
    },
    "🖋️Math🧮" : {
        lessons:[
            "Introduction to Numbers",
            "Addition and Subtraction",
            "Multiplication and Division Basics",
            "Fractions",
            "Measurement",
            "Geometry Basics",
            "Time and Money",
            "Patterns and Sequences"

        ]
    },
    "🔠Alphabets🔠" : {
        lessons:[
            "Introduction to the Alphabet",
            "Letter Recognition (A-M)",
            "Letter Recognition (N-Z)",
            "Uppercase and Lowercase Letters",
            "Letter Formation",
            "Letter Sounds",
            "Consonant Blends and Digraphs",
            "Vowels"
        ]
    },
    "🏰History🛖" : {
        lessons:[
            "Plants and Gardening",
            "Animals and Habitats",
            "The Solar System",
            "Weather",
            "States of Matter",
            "Simple Machines",
            "The Human Body",
            "Sound and Music",
            "Colors and Light",
            "Rocks and Minerals",
            "Electricity and Circuits"
        ]
    },
    "🦍🦁Animals🐯🐝" : {
        lessons:[
            "Introduction to Animal Classification",
            "Mammals",
            "Birds",
            "Fish",
            "Reptiles",
            "Amphibians",
            "Endangered Animals",
            "Animal Habitats",
            "Animal Behavior",
        ]
    },
}
export const subjectList=[
    "🥼🧪Science🔬🧬",
    "🖋️Math🧮",
    "🔠Alphabets🔠",
    "🏰History🛖",
    "🦍🦁Animals🐯🐝",
]