import React, { useEffect, useState } from 'react'
import Speech from "speak-tts";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Lottie from 'lottie-react'
import { SpriteAnimator } from 'react-sprite-animator'
import mia from './3.png'
import martha from './4.png'
import alice from './1.png'
import listeningAnimation from './listening.json'
import { GrChat, GrMicrophone, GrNext, GrPause, GrPlay, GrPrevious, GrSettingsOption } from "react-icons/gr";
import Navigation from '../Components/Navigation';
import ReactMarkdown from 'react-markdown';
import Navbar from '../Components/Navbar';
import { BiHome, BiLoader, BiMinus, BiMinusCircle, BiNotepad, BiPlayCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { classRooms } from '../Data/ClassroomData';
import { generateSpeech } from '../Models/ClassroomAgent';
export default function ChildClassroom() {
    const navigator=useNavigate()
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition();
    
    //const ollama_host = "http://localhost:11434"
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(false)
    const [lineDone, setLineDone] = useState(false)
    const [botReady, setBotReady] = useState(false)
    const [generating,setGenerating]=useState(false)
    const [generatingLecture,setGeneratingLecture]=useState(false)
    const [lecturing,setLecturing]=useState(false)
    const [currentOption, setCurrentOption] = useState("-1")


    const [lectureTranscript,setLectureTranscript]=useState(null)
    const [showTranscript,setShowTranscript]=useState(false)

    const [characterTalking,setCharacterTalking]=useState(false)
    const [talkStarted,setTalkStarted]=useState(false)
    const [bgUrl,setBgUrl]=useState()

    const [grabbingAudio,setGrabbingAudio]=useState(false)
    const [text,setText]=useState()

    const startListen=()=>{
        if(!browserSupportsSpeechRecognition){
            alert("Your browser does not support audio input")
            return
        }
        resetTranscript()
        SpeechRecognition.startListening({continuous:true,language:"en-US"})
    }
    const endListen=async()=>{
        SpeechRecognition.stopListening()
        setGenerating(true)
        talk("Let me think about that....")
        console.log("Transcript : ",transcript)
        const answer = await generateSpeech(`You are an agent that is teaching a 4 year old child about ${localStorage.getItem("sal-lesson")}. Please generate a proper answer based on this. Make sure the answer is atleast 200 words long. \n`,{transcript})
        //console.log(answer)
        setGenerating(false)
        talk(answer)
    }
    const generateLecture=async()=>{
        
        setLectureTranscript(null)
        setLecturing(true)
        talk("hmmmmmmmm. Let's start our lecture")
        setGeneratingLecture(true)
        const answer = await generateSpeech(`You are an agent that is teaching a 4 year old child about ${localStorage.getItem("sal-lesson")}. Please generate a proper answer based on this. Make sure the answer is atleast 700 words long`,"")
        setLectureTranscript(answer)
        setGeneratingLecture(false)
        await talk(answer)
        setLecturing(false)
    }
    const texts = {
        "1": [
            "I can help you learn topics that will help you be smart",
            "I can help you be more intelligent",
            "I will teach you new things",
        ],
        "2": [
            "My name is "+localStorage.getItem("sal-character"),
            "Your parents have assigned me as your teacher"
        ],
        "3": [
            "Change me!",
            "I am fully customizable! Meaning, you can modify my appearence, location, voice; everything!",
            "Just go to the settings and change me as you want!"
        ],
        "4": [
            "Something about myself",
            "I am your digital teacher who can help you learn",
        ],
        "5": [
            "I am currently teaching you about "+localStorage.getItem("sal-lesson"),
            "I am your digital teacher who can help you learn",
        ],
    }
    const questions = {
        "1": "What can you do?",
        "2": "What is your name?",
        "3": "I don't like your appearence.",
        "4": "Tell me something about yourself",
        "5": "What are you currently teaching?"
    }
    const opts = ["1", "2", "3", "4","5"]
    const incCount = (idx) => {
        if (count >= texts[idx].length - 1) {
            setCount(0)
            sayLine(0)
        }
        else {
            const a = count + 1
            setCount(a)
            sayLine(a)
        }

    }
    const decCount = (idx) => {
        if (count <= 0) {
            setCount(texts[idx].length - 1)
            sayLine(texts[idx].length - 1)
        }
        else {
            const a = count - 1
            setCount(a)
            sayLine(a)
        }


    }
    const speech = new Speech();
    const talk=async(sentence)=>{
        setStart(true)
        setLineDone(false)
        setTalkStarted(true)
        setCharacterTalking(true)
        try {
            speech.setLanguage("en-US")
            if(localStorage.getItem("sal-character")=="Martha"){
                speech.setVoice("Microsoft Clara Online (Natural) - English (Canada)")
            }else if(localStorage.getItem("sal-character")=="Alice"){
                speech.setVoice("Microsoft Luna Online (Natural) - English (Singapore)")
            }
            else if(localStorage.getItem("sal-character")=="Mia"){
                speech.setVoice("Microsoft Emily Online (Natural) - English (Ireland)")
            }
        } catch (e) {

        }
        await speech
            .speak({
                text: sentence,
                queue: false, // current speech will be interrupted,
                listeners: {
                    onstart: () => {
                        console.log("Start utterance")
                    },
                    onend: () => {
                        console.log("End utterance")
                    },
                    onpause: () => {
                        console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
                        setStart(false)
                    },
                    onresume: () => {
                        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE")
                        setStart(true)
                        console.log("Resume utterance")
                    },
                    onboundary: (event) => {
                        console.log(event.name + ' boundary reached after ' + event.elapsedTime + ' milliseconds.')

                    }
                }
            })
            .then((res) => {
                setStart(false)
                setCharacterTalking(false)
                setLineDone(true)
                setTalkStarted(false)
                
                //console.log("Success !");
            })
            .catch((e) => {
                setStart(false)
                setCharacterTalking(false)
                setTalkStarted(false)
                console.error("An error occurred :", e);
            });
    }
    const sayLine = (num) => {
        talk(texts[currentOption][num])
    };
    const stopSpeaking = async()=>{
        console.log("PAUSING PAUSED?",speech.paused())
        if(characterTalking){
            setCharacterTalking(false)
            await speech.pause()
        }else{
            resumeSpeaking()
        }
        // setCharacterTalking(false)
        // await speech.pause()
    }
    const resumeSpeaking = async()=>{
        console.log("RESUMING")
        setCharacterTalking(true)
        await speech.resume()
    }

    useEffect(() => {
        {/**Setting Default Classroom */}
        if(localStorage.getItem('sal-class')==null){
            localStorage.setItem("sal-class","bg1")
            setBgUrl("https://i.postimg.cc/0jmHPYFh/1.png")
        }else{
            setBgUrl(classRooms[localStorage.getItem('sal-class')].url)
        }
        
        speech
            .init({
                language: "en-US"
            })
            .then((data) => {
                //speech.setVoice("kathy")
                console.log("Speech is ready, voices are available", data);
                try {
                    speech.setLanguage("en-US")
                    if(localStorage.getItem("sal-character")=="Martha"){
                        speech.setVoice("Microsoft Clara Online (Natural) - English (Canada)")
                    }else if(localStorage.getItem("sal-character")=="Alice"){
                        speech.setVoice("Microsoft Zira Desktop - English (United States)")
                    }
                    else if(localStorage.getItem("sal-character")=="Mia"){
                        speech.setVoice("Microsoft Molly Online (Natural) - English (New Zealand)")
                    }
                } catch (e) {

                }

            }).then(data => {
                //speech.setLanguage("en-US")
                //speech.setVoice("Microsoft Clara Online (Natural) - English (Canada)")
                setBotReady(true)
            })
            .catch((e) => {
                //alert("An error occured while initializing ");
                console.error("An error occured while initializing : ", e);
            });


    }, [])
    useEffect(() => {
        if (currentOption != -1) {
            setCount(0)
            sayLine(0)
        }
    }, [currentOption])

    return (
            <div className='w-screen min-h-screen flex md:mb-0 flex-col items-center justify-center dark:bg-gray-900'>
                {/* <h1 className='text-3xl md:mt-24 mb-20 dark:text-white' onClick={() => setStart(false)}>Ennovators 7.0 Chat agent</h1> */}
                <button type="button" onClick={() => incCount(currentOption)} class="hidden md:fixed md:bottom-10 right-10 text-white shadow-lg bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-full text-sm p-2  mx-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"><GrSettingsOption size={32} /></button>
                <div style={{backgroundImage: `url(${bgUrl})`}} className={`flex md:flex-row flex-col w-full h-screen items-end justify-center bg-cover md:bg-cover bg-no-repeat rounded-3xl p-4 border border-gray-300 dark:border-gray-600 shadow-md mr-5`}>
                    <div className="fixed top-4 left-4 items-center">
                        {!lecturing && currentOption != "-1" && <p className='px-5 py-2 rounded-xl md:rounded-full bg-primary-500 text-white '>{texts[currentOption][count]}</p>}
                    </div>
                    {generating && <div className="fixed top-4 left-4 items-center">
                        <p className='px-5 py-2 rounded-xl md:rounded-full bg-primary-500 text-white '>Ummm... Let me think about that</p>
                    </div>}
                    {localStorage.getItem("sal-character")=="Martha" &&<SpriteAnimator
                        width={300}
                        height={560}
                        sprite={alice}
                        shouldAnimate={start}
                        fps={8}
                        startFrame={0}
                        //stopLastFrame={true}
                        scale={0.75}
                        className="-mb-4 md:mb-14"
                    //reset={!start}
                    />}
                    {localStorage.getItem("sal-character")=="Alice" &&<SpriteAnimator
                        width={300}
                        height={560}
                        sprite={martha}
                        shouldAnimate={start && characterTalking}
                        fps={8}
                        startFrame={0}
                        //stopLastFrame={true}
                        scale={0.75}
                        className="-mb-4 md:mb-14"
                    //reset={!start}
                    />}
                    {localStorage.getItem("sal-character")=="Mia" &&<SpriteAnimator
                        width={300}
                        height={560}
                        sprite={mia}
                        shouldAnimate={start && characterTalking}
                        fps={8}
                        startFrame={0}
                        //stopLastFrame={true}
                        scale={0.75}
                        className="-mb-4 md:mb-14"
                    //reset={!start}
                    />}
                </div>
                    
                {/**BUTTONS */}
                <button type="button" onClick={() => navigator("/parent-options")} class="z-20 fixed left-4 bottom-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiHome size={32} /></button>
                <button type="button" onClick={() => generateLecture()} class="z-20 fixed right-24 bottom-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiPlayCircle size={32} /></button>
                {!listening && <button type="button" onClick={() => startListen()} class="z-20 fixed right-4 bottom-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><GrMicrophone size={32} /></button>}
                {listening && <button type="button" onClick={() => endListen()} class="z-20 fixed right-4 bottom-4 bg-red-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><GrMicrophone size={32} /></button>}
                <div className="bg-white dark:bg-slate-900 fixed -bottom-2 py-6 flex  w-full  items-center justify-center my-2">
                    {(!lecturing && !generating) && <button type="button" onClick={() => decCount(currentOption)} class="hover:text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><GrPrevious size={18} /></button>}
                    {generatingLecture && <button type="button" class="hover:text-white shadow-lg bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"><BiLoader size={18} /></button>}
                    {characterTalking && <button type="button" onClick={stopSpeaking} class="hover:text-white shadow-lg bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"><GrPause size={18} /></button>}
                    {!characterTalking && talkStarted && !lineDone && <button type="button" onClick={resumeSpeaking} class="hover:text-white shadow-lg bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-full text-sm p-2  mx-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"><GrPlay size={18} /></button>}
                    {(!lecturing && !generating) && !lineDone && <button type="button" disabled onClick={() => incCount(currentOption)} class="hover:text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2  mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><GrNext size={18} /></button>}
                    {(!lecturing && !generating) && lineDone && <button type="button" onClick={() => incCount(currentOption)} class="hover:text-white shadow-lg bg-primary-400 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-full text-sm p-2  mx-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"><GrNext size={18} /></button>}
                </div>
                {/**OPTIONS */}
                    <div className="hidden md:block bg-white dark:bg-slate-900 md:fixed  top-4 right-4 rounded-3xl shadow-3xl border border-gray-200 flex flex-col px-4 pt-4 pb-16 max-w-sm items-center ">
                    {/* <input type="email" id="helper-text" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Chat with me"/> */}
                    <h3 className='bg-primary-400 py-2 w-full rounded-lg text-center font-semibold text-white text-lg'>Command Board</h3>
                    {botReady && opts.map(a => (
                        <button type="button" onClick={() => {
                            setCurrentOption(a)
                            console.log(currentOption)
                        }} class="mt-2 w-full dark:text-white hover:text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">{questions[a]}</button>
                    ))}
        
                </div>
                {/**TRANSCRIPT */}
                {lectureTranscript!=null &&!showTranscript && <button type="button" onClick={() => setShowTranscript(true)} class="z-20 fixed left-4 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiNotepad size={32} /></button>}
                {lectureTranscript!=null && showTranscript && <div className="bg-white dark:bg-slate-900 fixed top-4 left-4 rounded-3xl shadow-3xl border border-gray-200 flex flex-col px-4 pt-4 pb-4 max-w-sm items-center ">
                    {/* <input type="email" id="helper-text" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Chat with me"/> */}
                    <BiMinusCircle className='absolute top-2 right-2 rounded-full text-white p-1 bg-red-500' size={24} onClick={()=>setShowTranscript(false)}/>
                    <h3 className='bg-primary-400 px-4 py-2 w-full rounded-lg text-center font-semibold text-white text-lg'>Lecture Transcript</h3>
                    <div className='no-scrollbar max-h-[400px] overflow-y-scroll pt-4 text-sm' >
                        {/* <pre className='flex flex-wrap dark:text-white' style={{whiteSpace:"pre-wrap"}}>{lectureTranscript}</pre> */}
                        <ReactMarkdown className='dark:text-white' children={lectureTranscript} />
                    </div>
        
                </div>}
                {/**AUDIO */}
                {listening &&  <div className="bg-white fixed bottom-24 right-4 rounded-3xl shadow-3xl border border-gray-200 flex flex-col p-4 max-w-sm items-center ">
                    <Lottie animationData={listeningAnimation} loop={true} style={{width:"150px"}}/>
                    <p className='text-center mt-4'>{transcript}</p>
                </div>}
                {/**GENERATING */}
                {generating &&  <div className="bg-white fixed bottom-24 right-4 rounded-3xl shadow-3xl border border-gray-200 flex flex-col p-4 max-w-sm items-center ">
                    <Lottie animationData={listeningAnimation} loop={true} style={{width:"150px"}}/>
                    <p className='text-center mt-4'>Generating Answer...</p>
                </div>}
                
            </div>
    )
}
/*WWrite me a product description in markdown of blue ripped jeans for men. Make sure the markdown is correct. The answer should be descriptive. Include the points : title, subtitle, price, benefits, size chart,usage, caution.*/