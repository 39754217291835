import React from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'
export default function SyllabusCongratulations() {
    const navigator=useNavigate()
    
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center dark:bg-gray-900'>
        <Confetti/>
        <div class="z-20 flex flex-col items-center justify-center max-w-2xl p-24 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 class="mb-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white">🎉🎉Congratulations!🎉🎉</h5>
            <p class="font-normal text-center text-gray-700 dark:text-gray-400">You have completed curriculumn selection for your child</p>
            <button onClick={()=>navigator("/child-classroom")} class="inline-flex items-center mt-4 px-3 py-2 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Go to classroom
            </button>
        </div>
        
    </div>
 

  )
}
