import React from 'react'
import MDEditor from '@uiw/react-md-editor'
import { RiReplyAllFill } from 'react-icons/ri'
export default function AssistantChatLong({text,askedBy,replyTo,avatar}) {
  return (
    <div className="flex my-2">
        <img className="w-12 h-12 p-1 mr-1 rounded-full bg-gray-200" src={avatar}/>
        <div href="#" class="w-full p-4 border-2 bg-indigo-100 border-indigo-500 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            {/*<pre style={{whiteSpace:"pre-wrap"}}>{text}</pre>*/}
            {replyTo && <div className='w-full p-2 bg-white rounded-md mb-2'>
              <p className='text-xs flex items-center space-x-2'><RiReplyAllFill/><span>Reply to {askedBy}</span></p>
              <p className='text-sm font-semibold'>{replyTo}</p>
            </div>}
            <MDEditor.Markdown className='dark:hidden text-xs' source={text} style={{ background: "transparent",color:"black",fontSize:"13px",fontFamily:"Poppins" }} />
            <MDEditor.Markdown className='hidden dark:block text-xs' source={text} style={{ background: "transparent",fontSize:"13px",fontFamily:"Poppins" }} />
            
        </div>
    </div>
  )
}
