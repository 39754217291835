import React from 'react'
import Navbar from '../Components/Navbar'
import OptionCard from '../Components/Audience/OptionCard'
import { FaAngleLeft } from 'react-icons/fa'
import {motion} from 'framer-motion'
export default function StudentOptions() {
  return (
    <div >
       <Navbar/>
       <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} className='fixed top-24 left-4 p-2 text-xl border rounded bg-gray-100 hover:bg-gray-200 dark:bg-slate-800 dark:border-slate-700 dark:text-gray-200 dark:hover:bg-slate-700'><FaAngleLeft/></motion.button>
        <div className='w-full min-h-screen flex flex-col items-center justify-center bg-white dark:bg-gray-900'>
        <h2 class="mb-8 mt-4 md:mt-24 text-left md:text-left text-৩xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">I want to ...</h2>
            <div className='m-2 grid grid-cols-1 md:grid-cols-5 gap-4 p-12'  >
                <OptionCard path="chat-books" title="Chat with Books" image="https://i.postimg.cc/t45PwSfX/6.png" subtitle="Upload books and get answers of your questions directly from your book with sources"/>
                <OptionCard online={true} path="chat-video" title="Chat with Videos" image="https://i.postimg.cc/xTLLyT3N/8.png"  subtitle="Upload a youtube video link and ask questions on that video. Chat with the video content"/>
                <OptionCard path="chat-teach" title="Learn by Teaching" image="https://i.postimg.cc/sfQYxJBc/10.png"  subtitle="Upload a content and get questions in natural language. Answer them in your own way and get assessed."/>
                <OptionCard path="chat-agent" title="Chat with Agent" image="https://i.postimg.cc/sgy7ndT6/7.png"  subtitle="Offline AI Chatbot that you can access from anywhere"/>
                <OptionCard path="collaborative-classroom" title="Collaborative Classroom" image="https://i.postimg.cc/WpBMkJgP/9.png"  subtitle="Invite your friends to your classroom and study together"/>
              
            </div>
        </div>
        
    </div>
  )
}
