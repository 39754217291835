export const classRooms={
    "bg1":{
        name:"Classroom 1",
        url:"https://i.postimg.cc/0jmHPYFh/1.png"},
    "bg2":{
        name:"Classroom 2",
        url:"https://i.postimg.cc/P5M9CMJj/10.png"},
    "bg3":{
        name:"Classroom 3",
        url:"https://i.postimg.cc/c18zRWCT/2.png"},
    "bg4":{
        name:"Classroom 4",
        url:"https://i.postimg.cc/9fFSZqwq/3.png"},
    "bg5":{
        name:"Classroom 5",
        url:"https://i.postimg.cc/rwqY2tJY/4.png"},
    "bg6":{
        name:"Classroom 6",
        url:"https://i.postimg.cc/DZRY9jjq/5.png"},
    "bg7":{
        name:"Classroom 7",
        url:"https://i.postimg.cc/dtPxnfTF/6.png"},
    "bg8":{
        name:"Classroom 8",
        url:"https://i.postimg.cc/jdHk1hz7/7.png"},
    "bg9":{
        name:"Classroom 9",
        url:"https://i.postimg.cc/tTsMmQ5q/8.png"},
    "bg10":{
        name:"Classroom 10",
        url:"https://i.postimg.cc/CKP2NLbr/9.png"},

}
export const classRoomList=[
    "bg1",
    "bg2",
    "bg3",
    "bg4",
    "bg5",
    "bg6",
    "bg7",
    "bg8",
    "bg9",
    "bg10"
]