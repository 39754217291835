import React, { useState } from 'react'
import SessionCard from '../Dashboard/SessionCard';
import { FaAngleLeft } from 'react-icons/fa';

export default function CreateSession({nextPath}) {
    const sessionCount=2;
    const [formData,setFormData]=useState({})
    const handleChange=(e)=>{
        const {name,value}=e.target
        setFormData({
            ...formData,
            [name]: value,
          });
    }
    return (
        <div className='w-screen h-screen p-8 py-24 flex flex-col items-center justify-between'>
            <button className='fixed top-4 left-4 p-2 bg-gray-300 border rounded text-xl hover:bg-gray-400'><FaAngleLeft/></button>
            <div className='text-center'>
                <h2 className='text-4xl font-semibold mb-2'>You already have <span className='text-5xl font-bold bg-emerald-700 p-2 rounded-lg text-gray-200'>{sessionCount}</span> sessions running</h2>
                {sessionCount>=3 && <p className='text-sm text-gray-500'>You will have to close sessions to create a new one.</p>}
                {sessionCount<3 && <p className='text-sm text-gray-500'>Your session quota is available. You can create a session.</p>}
            </div>
            <div className='w-full md:w-1/3 my-4'>
                <form>
                    <label className='text-sm font-semibold text-emerald-700'>Session Title</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.session_name}
                        onChange={handleChange}
                        placeholder="Session Name"
                        className="w-full p-3 border border-gray-300 outline-none focus:border-emerald-700 rounded-lg"
                    />
                </form>
                <button className="bg-emerald-700 px-4 py-2 rounded-lg my-2 text-md font-semibold text-gray-200 hover:bg-emerald-800">Create Session</button>
            </div>
            <div className='my-4'>
                <h2 className='text-lg font-semibold'>These are the active sessions that you have</h2>
                <p className='text-sm text-gray-500 mb-2'>If you remove a session, only the PDF data gets removed. You can still view the conversation history later on.</p>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4'>
                    <SessionCard active={true}/>
                    <SessionCard active={true}/>
                    <SessionCard active={true}/>
                </div>
            </div>
        </div>
    )
}
