import { supabase } from "../supabaseClient"
export const createSessionNote=async(data)=>{
    const res=await supabase
    .from('session_note_public') 
    .insert([{
        "session_id": data.session_id
     }]).select();
    console.log(res)
    return res.data[0]
}
export const getSessionNote=async(id)=>{
    const res=await supabase
    .from('session_note_public') 
    .select()
    .eq('session_id',id)
    
    return res.data[0]
}
export const updateSessionNote=async(data)=>{
    console.log(data)
    const res=await supabase
    .from('session_note_public') 
    .update({
        content:data.content
    })
    .eq('session_id',data.session_id)
    .select()
    console.log(res)
    return res.data
}