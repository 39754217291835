import React,{useState,useEffect} from 'react'
import {motion} from 'framer-motion'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CreateProjectModal from '../../Components/DatasetGenerator/CreateProjectModal'
import ProjectCard from '../../Components/DatasetGenerator/ProjectCard'
import { MdAdd } from 'react-icons/md'
export default function CreateProject() {
    const navigate=useNavigate()
    const [loading,setLoading]=useState(false)
    const [showCreateProjectModal,setShowCreateProjectModal]=useState(false)
    const closeModal=()=>{
        setShowCreateProjectModal(false)
    }
    const projects=[
        {
            title:"My favourite Dataset",
            subtitle:"This is the best dataset that we currently have on physics",
            created_at:"12/21/2012",
            topic:"Physics",
            subtopic:"Magnetism",
            description:"Contains all our data on magnetism",
            generated_datapoint:123,
            estimated_cost:10123,
            members:[],
            datasets:[]

        },
        {
            title:"My favourite Dataset",
            subtitle:"This is the best dataset that we currently have on physics",
            created_at:"12/21/2012",
            topic:"Physics",
            subtopic:"Magnetism",
            description:"Contains all our data on magnetism",
            generated_datapoint:123,
            estimated_cost:10123,
            members:[],
            datasets:[]

        },
    ]
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
    <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
        <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
            <FaAngleLeft/>
        </motion.button>
        <h2 className='text-md md:text-xl font-semibold ml-4'>My Projects </h2>

    </div>
    <button onClick={()=>{setShowCreateProjectModal(true)}} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><MdAdd className='text-xl'/>Create New</button>
    <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
        <h2 className='text-left text-3xl font-bold '>Manage <span className='text-emerald-700 '>Projects</span></h2>
        <p className='text-sm font-gray-600'>Create and manage your data collection projects</p>
        <div className='w-full max-w-7xl grid grid-cols-1 gap-4 mt-6'>
            {!loading && projects.map(a=>(<ProjectCard data={a}/>))}
            {loading && <p>Loading Projects...</p>}
        </div>
    </div>
    {showCreateProjectModal && <CreateProjectModal closeModal={closeModal}/>}
</div>
  )
}
