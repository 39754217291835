import { supabase } from "../supabaseClient"
export const createSessionMessage=async(data)=>{
    try {
        const res = await supabase
            .from('session_message') 
            .insert([{
                "session_id":data.session_id,
                "type": data.type,
                "message": data.message,
                "reply_to": data.reply_to,
                "serial":data.serial,
                "user_name":data.user_name,
                "user_designation":data.user_designation,
                "user_id":data.user_id
            }]).select();

        //console.log(res)
        return res.data
    }catch(e){
        console.log(e)
    }
}
export const getMessagesBySession=async(id)=>{
    const res = await supabase
            .from('session_message') // Specify the table name
            .select() // Select all columns (you can specify specific columns as needed)
            .eq('session_id', id); // Apply filter where session_id equals the provided id
    
    //console.log(res.data)
    return res.data
}