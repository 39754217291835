import axios from "axios";

export const generateSpeech=async(context,question)=>{
    let retVal;
    const ollama_host = "http://localhost:11434"
    const requestBody={
        model:"llama3.1",
        prompt:"Your context is :\n"+context+"\nNow answer this question :\n"+question,
        stream:false
    }
  
    const request={
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(requestBody)
    }
    //const response = await(ollama_host+"/api/generate",request)
    //onsole.log(response)
   await fetch(ollama_host+"/api/generate",request)
    .then(response=>response.json())
    .then(data=>{
        //console.log(data)
        retVal=data.response
        //console.log(retVal)
        
    })
    return retVal
    
}
export const getOllamaAnswer=async(question,modelName="llama3")=>{
    const ollama_host = "http://localhost:11434"
    const res = await axios.post(ollama_host+"/api/generate",{
        model:"llama3.1",
        prompt:question,
        stream:false
    })
    return res.data.response
    
}