import React from 'react'

export default function BasicInfo({user}) {
  return (
    <div>
        <div className="bg-indigo-700 text-white p-6 border rounded-t-lg">
            <div className="flex items-center">
            <img 
                src={user.profileImage || "Salman Sayeed"} 
                alt={user.name  || "Salman Sayeed"} 
                className="w-24 h-24 rounded-full border-4 border-white mr-6"
            />
            <div>
                <h1 className="text-3xl font-bold">{user.name  || "Salman Sayeed"}</h1>
                <p className="text-indigo-200">{user.role  || "Salman Sayeed"}</p>
            </div>
            </div>
        </div>
        
        <div className="p-6 border border-t-0 rounded-b-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
                <h2 className="text-xl font-semibold mb-4 text-indigo-700">Personal Information</h2>
                <p className="mb-2 text-gray-600"><span className="font-semibold text-indigo-500">Email:</span> {user.email || "Salman Sayeed"}</p>
                <p className="mb-2 text-gray-600"><span className="font-semibold text-indigo-500">Company:</span> {user.department || "Salman Sayeed"}</p>
                <p className="mb-2 text-gray-600"><span className="font-semibold text-indigo-500">Join Date:</span> {user.joinDate || "Salman Sayeed"}</p>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-4 text-indigo-700">Bio</h2>
                <p className="text-gray-600">{user.bio  || "Salman Sayeed"}</p>
            </div>
            </div>
            
            {/* <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4 text-indigo-700">Skills</h2>
            <div className="flex flex-wrap">
                {user.skills.map((skill, index) => (
                <span 
                    key={index} 
                    className="bg-indigo-100 text-indigo-700 px-3 py-1 rounded-full text-sm font-medium mr-2 mb-2"
                >
                    {skill}
                </span>
                ))}
            </div>
            </div> */}
        </div>
    </div>
  )
}
