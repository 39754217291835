import React from 'react'
import {motion} from 'framer-motion'
import { MdDelete } from 'react-icons/md'
export default function FriendsPanel() {
  return (
    <div className='relative w-full p-6 shadow-lg rounded-lg border'>
        <p className='absolute top-4 right-4 text-xl rounded text-gray-200 bg-emerald-700 px-2 py-1 font-semibold'>17 Friends</p>
        <h4 className='text-lg font-semibold text-emerald-700 mb-4'>My Friends</h4>
        <div className='w-full flex items-center justify-between shadow-lg rounded-lg border p-4'>
            <div className='flex'>
                <img alt="DP" className='w-12 h-12 rounded-full' src="logo.png"/>
                <div className='ml-4'>
                    <p className='text-md font-semibold text-emerald-700'>Salman Sayeed</p>
                    <p className='text-xs'>Notre Dame College</p>
                    <p className='text-xs'>Friend Since : <span className='font-semibold'>Mar 2024</span></p>
                </div>
            </div>
            <div className='flex'>
                <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='flex items-center gap-x-2 text-xs font-semibold p-2 text-red-500  rounded border border-red-500 hover:bg-red-600 hover:text-white'><MdDelete/>Remove Friend</motion.button>
            </div>
        </div>
    </div>
  )
}
