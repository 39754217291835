import React, { useRef, useState, useEffect} from "react";
import { MdClose, MdMessage } from "react-icons/md";
import {motion} from 'framer-motion'
import { getOllamaAnswer } from "../../Models/ClassroomAgent";
import { BsCopy } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";

export default function PlaygroundModal({closeModal,ollamaModels}) {
    const inputAreaRef=useRef(null)
    const [input,setInput]=useState("")
    const [loading,setLoading]=useState(false)
    const [answer,setAnswer]=useState(null)
    const [selectedModel,setSelectedModel]=useState("llama3:latest")
    const handlePrompt=async()=>{
        setLoading(true)
        setAnswer(null)
        const ans = await getOllamaAnswer(input)
        setAnswer(ans)
        setLoading(false)
    }
    useEffect(() => {
        const textarea = inputAreaRef.current;
        if(textarea){
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      }, [input]);

    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="relative bg-white m-4 rounded-md shadow-lg max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">Playground🏋️‍♂️</h2>
                <h2 className="text-sm">Use this in cases where the default model isn't generating proper questions/answers</h2>
            </div>
            <div className="w-full flex items-center justify-center p-6 ">
            <select className='text-sm p-1 outline-none' value={selectedModel} onChange={(e)=>setSelectedModel(e.target.value)}>
              <option value="gpt-4o">GPT 4-o</option>
              <option value="claude-3.5-opus">Claude 3.5 Opus</option>
              {ollamaModels.map(a=>(
                <option value={a.model}>{a.name}</option>
              ))}
            </select> 
            </div>
            <div className="space-y-4 px-12 pb-12">
                <div className='w-full'>
                    <label className="block text-sm text-gray-700 font-semibold">
                    Questioning System Prompt
                    </label>
                    <div className="flex flex-col items-start">
                        <textarea
                            ref={inputAreaRef}
                            type="text"
                            name="name"
                            rows={1}
                            value={input}
                            onChange={(e)=>setInput(e.target.value)}
                            placeholder="Enter Your Prompt"
                            className="w-full text-sm p-3 border border-gray-300 rounded"
                        />
                        {answer && <div className='my-1 text-justify max-h-[300px] overflow-y-auto'>
                            <p className='px-2 text-md font-bold border-l-4 border-gray-800'>Answer</p>
                            <p className='px-2 text-sm text-gray-600 border-l-4 border-gray-800'>{answer}</p>
                        </div>}
                        <div className="w-full flex justify-between my-2">
                            {!loading && answer && <button onClick={()=>navigator.clipboard.writeText(answer)} className="font-semibold text-xs flex items-center space-x-2 text-emerald-700 px-4 py-2 rounded border border-emerald-700 shadow hover:bg-emerald-700 hover:text-gray-200"><FaCopy className="text-xl mr-2"/>Copy Answer</button>}
                            <button onClick={handlePrompt} className="font-semibold text-xs flex items-center space-x-2 text-emerald-700 px-4 py-2 rounded border border-emerald-700 shadow hover:bg-emerald-700 hover:text-gray-200"><MdMessage className="text-xl mr-2"/>{!loading ? "Send" : "Loading Answer..."}</button>
                        </div>
                        
                    </div>
                   
                </div> 
                 
            </div>
            
        </motion.div>
      </div>
    );
  }