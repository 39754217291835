import React, { useRef, useState, useEffect} from "react";
import { MdClose } from "react-icons/md";
import {motion} from 'framer-motion'

export default function SystemPromptModal({closeModal,questionPrompt,answerPrompt,changeQuestionPrompt,changeAnswerPrompt }) {
    const questionAreaRef=useRef(null)
    const answerAreaRef=useRef(null)
    const [active,setActive]=useState("question")

    useEffect(() => {
        const textarea = questionAreaRef.current;
        if(textarea){
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      }, [questionPrompt]);
    useEffect(() => {
        const textarea = answerAreaRef.current;
        if(textarea){
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      }, [answerPrompt]);
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <motion.div initial={{translateY:-window.innerHeight/2}} animate={{translateY:0}} className="relative bg-white m-4 rounded-md shadow-lg max-w-3xl">
            <div className="flex flex-col justify-center rounded-t-md bg-emerald-700 py-12 text-gray-200 px-8">
                <button onClick={closeModal} className="absolute top-6 right-6 text-xl hover:text-gray-300"><MdClose className="hover:color-gray-500"/></button>
                <h2 className="text-2xl font-bold text-white">System Prompts</h2>
                <h2 className="text-sm">Change System Prompts for the Question and answer generator</h2>
            </div>
            <div className="w-full flex items-center justify-center p-6 ">
                {active==="question" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Question Prompt</button>}
                {active!=="question" && <button onClick={()=>setActive("question")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Question Prompt</button>}
                {active==="answer" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Answer Prompt</button>}
                {active!=="answer" && <button onClick={()=>setActive("answer")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Answer Prompt</button>}
            </div>
            <div className="space-y-4 px-12 pb-12">
                {active==="question" && <div className='w-full'>
                    <label className="block text-sm text-gray-700 font-semibold">
                    Questioning System Prompt
                    </label>
                    <textarea
                        ref={questionAreaRef}
                        type="text"
                        name="name"
                        value={questionPrompt}
                        onChange={(e)=>changeQuestionPrompt(e.target.value)}
                        placeholder="Enter Your Prompt"
                        className="w-full text-sm p-3 border border-gray-300 rounded"
                    />
                    <div className='w-full flex justify-end'>
                        <button onClick={closeModal} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Save Prompt</button>
                    </div>
                </div>}  
                {active==="answer" && <div className='w-full'>
                    <label className="block text-sm text-gray-700 font-semibold">
                    Answering System Prompt
                    </label>
                    <textarea
                        ref={answerAreaRef}
                        type="text"
                        name="name"
                        value={answerPrompt}
                        onChange={(e)=>changeAnswerPrompt(e.target.value)}
                        placeholder="Enter Your Prompt"
                        className="w-full text-sm p-3 border border-gray-300 rounded"
                    />
                    <div className='w-full flex justify-end'>
                        <button onClick={closeModal} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Save Prompt</button>
                    </div>
                </div>}  
            </div>
            
        </motion.div>
      </div>
    );
  }