import React from 'react'
import { useNavigate } from 'react-router-dom'
import {motion} from 'framer-motion'
export default function OptionCard({image,title,subtitle,path,online}) {
  
  const navigator=useNavigate()
  return (
        <motion.div 
          initial={{translateY:"50px"}}
          animate={{translateY:"0px"}}
          whileHover={{translateY:"-5px"}}
          onClick={()=>navigator("/"+path)} 
          className="block cursor-pointer max-w-xs md:max-w-sm p-12 bg-white border border-gray-200 rounded-lg shadow hover:border-amber-500 hover:bg-amber-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          {!online && <span class="flex bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"><span class="flex w-3 h-3 me-3 bg-green-500 rounded-full"></span>Works Offline</span>}
          {online && <span class="flex bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"> <span class="flex w-3 h-3 me-3 bg-yellow-300 rounded-full"></span> Requires Internet</span>}
          <img src={image} />
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">{subtitle}</p>
        </motion.div>

  )
}
