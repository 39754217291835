import React from 'react'
import ProfileSessionCard from './ProfileSessionCard'

export default function MySessions({openSession}) {
  return (
    <div className='my-4'>
        <div className="bg-indigo-700 text-white px-6 py-4 border rounded-t-lg">
            <h1 className="text-3xl font-bold">My Sessions</h1>
            <p className="text-indigo-200">All the sessions I had on StudyGazelle</p>
        </div>
        <div className=" p-6 border border-t-0 rounded-b-lg">
            <ProfileSessionCard openSession={openSession}/>
            <ProfileSessionCard openSession={openSession}/>
            <ProfileSessionCard openSession={openSession}/>
        </div>
    </div>
  )
}
