import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Audience from './Pages/Audience';
import ParentOptions from './Pages/ParentOptions';
import SetupCharacter from './Pages/Settings/SetupCharacter';
import SetupCurriculumn from './Pages/Settings/SetupCurriculumn';
import SetupClassroom from './Pages/Settings/SetupClassroom';
import StudentOptions from './Pages/StudentOptions';
import SyllabusCongratulations from './Pages/Settings/SyllabusCongratulations';
import ChildClassroom from './Pages/ChildClassroom';
import ChatAgent from './Pages/Chatter/ChatAgent';
import CollaborativeClassroom from './Pages/CollaborativeClassroom/CollaborativeClassroom';
import ChatWBooks from './Pages/Chatter/ChatWBooks';
import ChatVideos from './Pages/Chatter/ChatVideos';
import { AuthProvider } from './Hooks/AuthContext';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import ProtectedRoutes from './ProtectedRoutes';
import ChatTeach from './Pages/Chatter/ChatTeach';
import ProfileHome from './Pages/Profile/ProfileHome';
import Dashboard from './Pages/Dashboard/Dashboard';
import CreateSession from './Pages/Chatter/CreateSession';
import Quiz from './Pages/Quiz/Quiz';
import FlashCards from './Pages/Flashcards/FlashCards';
import ExpertProfiling from './Pages/DataGenerator/ExpertProfiling';
import ExpertProfile from './Pages/DataGenerator/ExpertProfile';
import CreateProject from './Pages/DataGenerator/CreateProject';
import CreateQA from './Pages/DataGenerator/CreateQA';
import CreateDataset from './Pages/DataGenerator/CreateDataset';
import DataGeneratorHome from './Pages/DataGenerator/DataGeneratorHome';
import CollaborativeClassroomCreate from './Pages/CollaborativeClassroom/CollaborativeClassroomCreate';



function App() {

  return (
    <AuthProvider>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/login" element={<Login />} />
          
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/flashcards" element={<FlashCards />} />
          <Route path="/create-session" element={<CreateSession />} />
          <Route path="/profile" element={<ProfileHome />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dataset-generator" element={<DataGeneratorHome />} />
          <Route path="/dataset-generator/expert-profiling" element={<ExpertProfiling />} />
          <Route path="/dataset-generator/expert-profile" element={<ExpertProfile />} />
          <Route path="/dataset-generator/create-project" element={<CreateProject />} />
          <Route path="/dataset-generator/create-qa" element={<CreateQA />} />
          <Route path="/dataset-generator/create-dataset" element={<CreateDataset />} />
          <Route path="/collaborative-classroom" element={<CollaborativeClassroomCreate />} />
          <Route path="/collaborative-classroom/:id" element={<CollaborativeClassroom />} />
          <Route path="/audience" element={<Audience />} />
          <Route path="/chat-books" element={<ChatWBooks />} />
          <Route path="/chat-agent" element={<ChatAgent />} />
          <Route path="/chat-video" element={<ChatVideos />} />
          <Route path="/chat-teach" element={<ChatTeach />} />
          <Route path="/child-classroom" element={<ChildClassroom />} />
          <Route path="/parent-options" element={<ParentOptions />} />
          <Route path="/student-options" element={<StudentOptions />} />
          <Route path="/character-setup" element={<SetupCharacter />} />
          <Route path="/classroom-setup" element={<SetupClassroom />} />
          <Route path="/curriculumn-setup" element={<SetupCurriculumn />} />
          <Route path="/curriculumn-congrats" element={<SyllabusCongratulations />} />

        </Routes>

      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
