import React from 'react'

export default function ProfilePrompt() {
  return (
    <div className='w-full p-6 shadow-lg rounded-lg border'>
        <h4 className='text-lg font-semibold text-emerald-700'>Profile Prompt</h4>
        <h4 className='text-xs text-gray-700 mb-4'>This is a promot generated by StudyGazelle based on your <span className='font-semibold'>learning curve</span> and <span className='font-semibold'>questioning patterns</span></h4>
        <div className='flex flex-col items-start'>
            <p className='text-sm font-semibold text-emerald-700'>Questening Type : <span className='font-normal text-gray-700'>Analytical</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Questening Frequency : <span className='font-normal text-gray-700'>Often</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Preferred Answer length : <span className='font-normal text-gray-700'>Long Answers</span></p>
            <p className='text-sm font-semibold text-emerald-700'>Profiling Prompt : <p className='font-normal text-gray-700'>This user normally asks questions that is a follow up of the concept that has been previously asked. This student rarely asks questions on new concepts and likes elaborative answers more that short ones.</p></p>
        </div>
    </div>
  )
}
