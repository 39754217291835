import React from 'react'

export default function UserChatLong({text,avatar,myMessage,userName}) {
  return (
    <div className="flex my-2  ">
        <img className="w-12 h-12 p-1 mr-2 rounded-full bg-gray-200" src={avatar}/>
        <div class={`w-full ${myMessage && "bg-primary-400"} border border-gray-200 shadow-md text-gray-800 dark:text-gray-100 dark:bg-gray-800 rounded-md p-2 dark:border-gray-700`}>
            {myMessage ? <p className='text-xs font-semibold mb-1'>Me</p> : ( <p className='text-xs font-semibold mb-1'>{userName}</p>)}
            <p className='text-sm dark:text-gray-200' style={{fontFamily:"Poppins"}}>{text}</p>
        </div>
    </div>
  )
}
