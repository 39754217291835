import React, { useState } from 'react'
import Navbar from '../../Components/Navbar'
import { IoVideocam } from 'react-icons/io5'
import { AiOutlineEnter } from 'react-icons/ai'
import CommonModal from '../../Components/CommonModal'
import { createSession } from '../../API/Session'
import { useNavigate } from 'react-router-dom'
import {motion} from 'framer-motion'
import { createSessionUser } from '../../API/SessionUser'
import { createSessionNote } from '../../API/SessionNote'
export default function CollaborativeClassroomCreate() {
    const navigator= useNavigate()
    const [createSelected,setCreateSelected] = useState(false)
    const [sessionTitle,setSessionTitle] = useState(null)
    const [userName,setUserName] = useState(null)
    const [userDesignation,setUserDesignation] = useState(null)

    const createNewSession=async()=>{
        try{
            const newSession=await createSession({
                user_id:"e65047b8-e8a9-4b97-baed-507e98bda21c",
                user_name:userName,
                user_designation:userDesignation,
                title:sessionTitle,
                type:'collaborative_classroom'
            })
            const newSessionNote=await createSessionNote({
                session_id:newSession.id
            })
            localStorage.setItem("sal-session",newSession.id)
            localStorage.setItem("sal-session-username",userName)
            localStorage.setItem("sal-session-userdesignation",userDesignation)
            try{
                const res = await createSessionUser({
                    user_id:"e65047b8-e8a9-4b97-baed-507e98bda21c",
                    user_name:userName,
                    user_designation:userDesignation,
                    session_id:newSession.id
                })
                navigator(`/collaborative-classroom/${newSession.id}`)

            }catch(e){
                console.error(e)
            }
            
           
            console.log(newSession)
        }catch(e){
            console.error(e)
        }
    }
  return (
    <div className='w-screen min-h-screen pt-12 dark:bg-slate-800 dark:border-slate-700 dark:text-gray-200 dark:hover:bg-slate-700'>
        <Navbar/>
        <div className='w-full h-full flex flex-col items-center justify-center p-12'>
            <div className='mb-4'>
                <h2 className='text-center text-xl md:text-2xl lg:text-4xl font-semibold'>Create or Join a <span className='text-primary-500 font-semibold'>Collaborative Classroom</span></h2>
                <p className='text-center'>Create a classroom and invite your friends or join an exisiting classroom with a link</p>
            </div>
            <div className='grid grid-cols-2 gap-6 p-6 rounded-md border-primary-500 min-w-3xl max-w-7xl'>
                
                <motion.div onHover={{translateY:"-5px"}} onClick={()=>setCreateSelected(true)} className='p-12 py-24 flex flex-col cursor-pointer items-center justify-center border shadow-md border-gray-200 hover:border-primary-500 hover:bg-primary-50 rounded-xl dark:bg-gray-800 dark:border-gray-900 dark:hover:bg-gray-900'>
                    <IoVideocam className='w-32 h-32 text-primary-500'/>
                    <h4 className='text-2xl font-semibold text-primary-500'>Create New Session</h4>
                    <p className='text-gray-600 dark:text-gray-400'>Create a new session and invite your friends</p>
                </motion.div>
                <motion.div onHover={{translateY:"-5px"}} className='p-12 flex flex-col cursor-pointer items-center justify-center border shadow-md border-gray-200 hover:border-primary-500 hover:bg-primary-50 rounded-xl dark:bg-gray-800 dark:border-gray-900 dark:hover:bg-gray-900'>
                    <AiOutlineEnter className='w-32 h-32 text-primary-500'/>
                    <h4 className='text-2xl font-semibold text-primary-500'>Join Existing Session</h4>
                    <p className='text-gray-600 dark:text-gray-400'>Create a new session and invite your friends</p>
                </motion.div>
            </div>
        </div>
        {createSelected && <CommonModal title="Create New Classroom" isOpen={createSelected} onClose={()=>setCreateSelected(false)}>
        <div className="flex flex-col items-start w-full space-y-4 p-4">
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="w-full"
            >
                <label className="font-semibold text-gray-800 dark:text-gray-200">
                Session Title
                </label>
                <input
                value={sessionTitle}
                onChange={(e) => setSessionTitle(e.target.value)}
                className="w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-slate-700 dark:border-slate-800 dark:text-gray-100"
                placeholder="Enter Session Title"
                />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                className="w-full"
            >
                <label className="font-semibold text-gray-800 dark:text-gray-200">
                User Name
                </label>
                <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-slate-700 dark:border-slate-800 dark:text-gray-100"
                placeholder="Enter Your Name"
                />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full"
            >
                <label className="font-semibold text-gray-800 dark:text-gray-200">
                User Designation
                </label>
                <input
                value={userDesignation}
                onChange={(e) => setUserDesignation(e.target.value)}
                className="w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-slate-700 dark:border-slate-800 dark:text-gray-100"
                placeholder="Enter Your Designation"
                />
            </motion.div>

            <motion.button
                onClick={createNewSession}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-4 w-full text-lg font-semibold px-4 py-2 rounded-md bg-primary-500 hover:bg-primary-600 text-white shadow-md dark:text-slate-800"
            >
                Submit
            </motion.button>
        </div>
            
        </CommonModal>}
    </div>
  )
}
