import React from 'react'
import { useNavigate } from 'react-router-dom'
import {motion} from 'framer-motion'
export default function AudienceCard({image,title,subtitle,path}) {
  const navigator=useNavigate()
  return (
        <motion.div 
          initial={{translateY:"50px"}}
          animate={{translateY:"0px"}}
          whileHover={{translateY:"-5px"}} 
          onClick={()=>navigator("/"+path)} 
          className="block cursor-pointer max-w-xs md:max-w-sm p-12 bg-white border border-gray-200 rounded-lg shadow hover:border-amber-500 hover:bg-amber-50 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
          <img src={image} alt="card"/>
          <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
          <p class="font-normal text-gray-700 dark:text-gray-400">{subtitle}</p>
        </motion.div>
  )
}
