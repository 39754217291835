import React from 'react'
import Navbar from '../Components/Navbar'
import {motion} from 'framer-motion'
import OptionCard from '../Components/Audience/OptionCard'
import { FaAngleLeft } from 'react-icons/fa'
export default function ParentOptions() {
  return (
    <div >
        <Navbar/>
        <motion.button onClick={()=>window.history.back()} whileHover={{scale:1.05}} className='fixed top-24 left-4 p-2 text-xl border rounded bg-gray-100 hover:bg-gray-200 dark:bg-slate-800 dark:border-slate-700 dark:text-gray-200 dark:hover:bg-slate-700'><FaAngleLeft/></motion.button>
        <div className='w-screen min-h-screen flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
        <h2 class="mb-8 text-left md:text-left text-6xl tracking-tight font-bold text-gray-900 dark:text-white">I want to ...</h2>
            <div className=' grid grid-cols-1 md:grid-cols-4 gap-12'  >
                <OptionCard path="curriculumn-setup" title="Create Curriculumn" image="https://i.postimg.cc/59vZx5Gc/2.png" subtitle="I want help with my academic studies with AI tools like Chat with book, Chat with video, Learn by Teaching"/>
                <OptionCard path="character-setup" title="Change Character Appearence" image="https://i.postimg.cc/hPm3BVCJ/3.png"  subtitle="I want to create custom curriculumn for my child and teach him/her imporant concepts with the help of animated agents and dynamic content"/>
                <OptionCard path="classroom-setup" title="Change Classroom Appearence" image="https://i.postimg.cc/XY017Xhs/4.png"  subtitle="I want to create custom curriculumn for my child and teach him/her imporant concepts with the help of animated agents and dynamic content"/>
                <OptionCard path="child-classroom" title="Go to Classroom" image="https://i.postimg.cc/Qx0Sj4N7/1x1-Images.png"  subtitle="Start the interactive classroom for your child"/>
            </div>
        </div>
        
    </div>
  )
}
