import React from 'react'
import {motion} from 'framer-motion'
import { FaEye } from 'react-icons/fa'
import { MdDelete, MdEdit } from 'react-icons/md'

export default function ProfileNotesCard({openNote}) {
  return (
    <div className='w-full flex justify-between my-2 rounded-lg shadow-lg p-4 px-6 border'>
        <div>
            <h5 className='text-md font-semibold'>This is my note</h5>
            <p className='text-xs'>25/7/2024</p>
        </div>
        <div className='flex space-x-2'>
            {/* <div className='flex flex-col items-center justify-between'>
                <p className='text-xs font-semibold'>Public</p>
                <Checkbox className='w-6 h-6'/>
            </div> */}
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-green-500 text-xl rounded border border-green-500 hover:bg-green-600 hover:text-white'><FaEye/></motion.button>  
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-gray-500 text-xl rounded border border-gray-500 hover:bg-gray-600 hover:text-white'><MdEdit/></motion.button>  
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-red-500 text-xl rounded border border-red-500 hover:bg-red-600 hover:text-white'><MdDelete/></motion.button>
        </div>
    </div>
)
}
