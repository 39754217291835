import React,{useState,useEffect} from 'react'
import Navbar from '../../Components/Navbar'
import CharacterCard from '../../Components/Settings/CharacterCard'
import { MdDone } from 'react-icons/md'
import { CgClose } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'

export default function SetupCharacter() {
    const navigator=useNavigate()
    const [currChar, setCurrChar] = useState('english') 
    const [done,setDone]=useState(false)
    useEffect(()=>{
        if(localStorage.getItem("sal-character")==null){
            localStorage.setItem("sal-character","Alice")
        }
        setCurrChar(localStorage.getItem('sal-character'))
    },[])
    const changeCharacter=(character)=>{
        localStorage.setItem("sal-character",character)
        setCurrChar(character)
        setDone(true)
    }
    
  return (
    <div >

        <Navbar/>

        <div className='w-screen min-h-screen flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
        <h2 class="mb-8 mt-24 md:mt-0 text-center md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">Change the teaching character to be...</h2>
            <div className=' grid grid-cols-1 md:grid-cols-4 gap-8'  >
                <CharacterCard changeCharacter={changeCharacter} selected={currChar==="Alice"} name="Alice" image="https://i.postimg.cc/Dft3Z9wm/1x1-Images-1.png" />
                <CharacterCard changeCharacter={changeCharacter} selected={currChar==="Martha"} name="Martha" image="https://i.postimg.cc/44KTVn5J/Untitled-design.png" />
                <CharacterCard changeCharacter={changeCharacter} selected={currChar==="Mia"} name="Mia" image="https://i.postimg.cc/xTNfB02n/Ajaira-Stuff-2.png" />
                <CharacterCard changeCharacter={changeCharacter} selected={currChar==="Sam"} name="Sam" image="https://i.postimg.cc/xdn9kMb1/Ajaira-Stuff-3.png" />
               
            </div>
        </div>
        

        {/*TOAST*/}
        {done && <div class="fixed bottom-2 right-2 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow-lg dark:bg-gray-800 dark:text-gray-400" role="alert">
            <div class="flex">
                <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:text-blue-300 dark:bg-blue-900">
                    <MdDone/>
                </div>
                <div class="ms-3 text-sm font-normal">
                    <span class="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Character Change Complete</span>
                    <div class="mb-2 text-sm font-normal">Your new character is {currChar}.</div> 
                    <div class="grid grid-cols-1 gap-2">
                        <div>
                            <div class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800" onClick={()=>navigator("/child-classroom")}>Go to classroom</div>
                        </div>
                        <div>
                            <div class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700" onClick={()=>setDone(false)}>Not now</div> 
                        </div>
                    </div>    
                </div>
                <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" onClick={()=>setDone(false)}>
                    <CgClose/>
                </button>
            </div>
        </div>}


    </div>
  )
}
