import React from 'react'
import { FaFacebookF, FaTwitter, FaGithub, FaDribbble } from 'react-icons/fa';

export default function TeamMember({data}) {
  return (
    <div className="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
        <a href={data.portfolio}>
            <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" style={{ width: "200px" }} src={data.image} alt={data.name} />
        </a>
        <div className="p-5">
            <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                <a href={data.portfolio}>{data.name}</a>
            </h3>
            <span className="text-gray-500 dark:text-gray-400">{data.title}</span>
      
            <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">{data.subtitle}</p>
         
            <ul className="flex space-x-4 sm:mt-0">
                {data.facebook && <li>
                    <a href={data.facebook} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <FaFacebookF className="w-5 h-5" />
                    </a>
                </li>}
                {data.twitter && <li>
                    <a href={data.twitter} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <FaTwitter className="w-5 h-5" />
                    </a>
                </li>}
                {data.github && <li>
                    <a href={data.github} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <FaGithub className="w-5 h-5" />
                    </a>
                </li>}
                {data.dribble && <li>
                    <a href={data.dribble} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                        <FaDribbble className="w-5 h-5" />
                    </a>
                </li>}
            </ul>
        </div>
    </div>
  )
}