import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoClose } from "react-icons/io5";
const CommonModal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <AnimatePresence>
        {isOpen && (
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
            >
            <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-lg shadow-xl w-full max-w-6xl dark:bg-slate-800"
            >
                <div className="flex items-center justify-between bg-primary-500  px-6 py-4 rounded-t-lg">
                
                    <h2 className="text-xl font-semibold dark:text-slate-800">{title}</h2>
                    <button
                        onClick={onClose}
                        className="p-2 rounded-full hover:bg-primary-600 transition-colors duration-200 dark:text-slate-800"
                    >
                        <IoClose className="text-lg" />
                    </button>
                </div>
                <div className="p-6">
                    {children}
                </div>
                
            </motion.div>
            </motion.div>
        )}
        </AnimatePresence>
);};

export default CommonModal;