import { supabase } from "../supabaseClient"
export const createSessionUser=async(data)=>{
    const res=await supabase
    .from('session_user') 
    .insert([{
        "user_id":data.user_id ,
        "user_name":data.user_name,
        "user_designation":data.user_designation,
        "session_id": data.session_id
     }]).select();
    console.log(res)
    return res.data[0]
}
export const getSessionUsers=async(id)=>{
    const res=await supabase
    .from('session_user') 
    .select()
    .eq('session_id',id)

    return res.data
}