import React from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'

export default function DataGeneratorHome() { 
    const navigator=useNavigate()
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-emerald-50'>
        
        <div className='flex flex-col items-center text-center'>
           
            <p className='text-xl -mb-2'>(Streamlined Automated LLM Dataset Generator)</p>
            <h2 className='text-7xl font-bold text-emerald-900'>S.A.L. Dataset Generator</h2>
            <p className='text-2xl'>A platform for experts to generate datasets for LLM research</p>
            <motion.button onClick={()=>navigator("/menu")} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='text-xl mt-4 mb-12 font-semibold text-emerald-900 px-4 py-2 border-4 border-emerald-900  rounded'>Get Started</motion.button>
            <img className='w-1/2 mt-12' src="https://i.postimg.cc/XJg3yvXS/1x1-Images-34.png"/>
        </div>
     
        
        
        
    </div>
  )
}

