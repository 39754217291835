import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {motion} from 'framer-motion'
import { FaAngleLeft } from 'react-icons/fa'
import BasicInfo from '../../Components/Profile/BasicInfo'
import MyNotes from './MyNotes'
import MySessions from './MySessions'
export default function ProfileHome() {
    const [user,setUser]=useState({})
    const [noteModalValue,setNoteModalValue]=useState(null)
    const [openNoteModal,setOpenNoteModal]=useState(false)
    const [sessionModalValue,setSessionModalValue]=useState(null)
    const [openSessionModal,setOpenSessionModal]=useState(false)
    const navigate=useNavigate()
    const openNote=(noteValue)=>{
        setNoteModalValue(noteValue)
        setOpenNoteModal(true)
    }
    const openSession=(sessionValue)=>{
        setSessionModalValue(sessionValue)
        setOpenSessionModal(true)
    }
    return (
        <div className='w-screen min-h-screen pt-16 flex flex-col items-center p-8'>
            {/**CUSTOM NAVBAR */}
            <div className="fixed flex top-0 left-0 z-10 w-full h-12 bg-blue-50 dark:bg-blue-950 items-center">
                <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2 ml-4 bg-gray-200 rounded hover:bg-gray-300">
                    <FaAngleLeft/>
                </motion.button>
                <img alt="logo" width="25px" height="25px" className="mr-2 ml-6" src="https://i.postimg.cc/L8T1V5pD/image.png" onClick={() => navigator("/")}/>
                <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">My Profile</h2>
            </div>
            <div className='w-full max-w-7xl'>
                <BasicInfo user={user}/>
                <MyNotes openNote={openNote}/>
                <MySessions openSession={openSession}/>
            </div>

        </div>
    )
}
