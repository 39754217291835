import React from 'react'

import BasicInformation from '../../Components/Dashboard/BasicInformation'
import ProfilePrompt from './ProfilePrompt'
import SessionPanel from './SessionPanel'
import FriendsPanel from './FriendsPanel'
export default function Dashboard() {
  return (
    <div className='w-screen h-screen pl-24 p-8'>
        <div>
            <h2 className='text-2xl font-bold text-emerald-700'>Dashboard</h2>
            <h6 className='text-md font-normal text-gray-500'>View your whole learning curve in on place</h6>
        </div>
        <div className='grid grid-cols-2 gap-4 my-4'>
            <BasicInformation/>
            <ProfilePrompt/>
            
        </div>
        <div className='grid grid-cols-1 gap-4 my-4'>
            <FriendsPanel/>

        </div>
        <div className='grid grid-cols-1 gap-4 my-2'>
            <SessionPanel/>

        </div>
    </div>
  )
}
