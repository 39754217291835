import React, { useEffect, useRef, useState } from "react";


import { BACKEND_ENDPOINT } from "../../Data/APIEndpoints";
import axios from 'axios'
import { FaArrowLeft } from "react-icons/fa";
import { RiUploadCloud2Line } from "react-icons/ri";
import { IoCloudUploadOutline,IoSendSharp } from "react-icons/io5";
import { VscServerProcess } from "react-icons/vsc";
import AssistantRag from "../../Components/Chatter/AssistantRag";
import AssistantChatLong from "../../Components/Chatter/AssistantChatLong";
import UserChatLong from "../../Components/Chatter/UserChatLong";


// Import PDF Renderer Elements
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; //npm install pdfjs-dist@3.4.120
import Loader from "../../Components/Loader";


export default function ChatWBooks() {
    const bottomRef=useRef()

    const [currentUserChat,setCurrentUserChat]=useState(null)
    const [generatingChat,setGeneratingChat]=useState(false)
    const [uploadedFile,setUploadedFile]=useState(null)
    const [pdfUrl,setPdfUrl]=useState(null)
    const [uploadSuccess,setUploadSuccess]=useState(false)
    const [ingested,setIngested]=useState(false)
    const [ingesting,setIngesting]=useState(false)
    const [conversation,setConversation]=useState([
      {   
        role:"system",
        content:"You are an agent that helps users learn things. The user may ask you questions or tell you to explain certain things. Try to answer appropriately. If you give empty answers, you will be penalized 100 dollars. If you answer in markdown you will get 100 dollar reward"
      },
      {   
        role:"assistant",
        content:"Hello there I'm your assistant here to help you with anythng that you need. Please feel free to ask me any general questions about anything that you might want to know. I am here to assist you in any way that I can.I understand that I cannot give empty answers, so I will always make sure to give you a helpful response or redirect you to a source that can help you.Let me know how I can assist you today!"
      }
  ])
    
    const sendDocument=async()=>{
      if(uploadedFile==null){
        alert("Please select a PDF file first!")
        return
      }

      const formData=new FormData()
      formData.append('files[]',uploadedFile)
      //console.log(formData)
      const res=await axios.post(BACKEND_ENDPOINT+"/file-upload",formData)
      //console.log(res.data.status)
      if(res.data.status=="success"){
        setUploadSuccess(true)
        alert("Your file has been sent to model")
      }else{
        alert("Your file was not uploaded. Try with a smaller file please.")
      }
    }
    const ingestDocuments=async()=>{
      if(!uploadSuccess){
        alert("Upload your PDF to the model first!")
        return
      }
      setIngesting(true)
      const res=await axios.get(BACKEND_ENDPOINT+"/ingest")
      //console.log(res.data)
      if(res.data.status=="success"){
        setIngested(true)
        setIngesting(false)
        alert("Ingestion Complete! You can chat now!")
      }else{
        setIngesting(false)
        alert("Your ingestion was not complete. Please Try again!")
      }
    }

    const sendRAGQuery=async()=>{
      setCurrentUserChat("")
      if(!ingested){
        alert("You need to upload and ingest your document before asking questions")
        return
      }
      setGeneratingChat(true)
      const userConvBody={
        role:"user",
        content:currentUserChat
      }
      setConversation((conversation)=>[...conversation,userConvBody])
      
      const res=await axios.post(BACKEND_ENDPOINT+"/rag",{
        "query":currentUserChat
      })
      console.log(res.data)
      const assistantConv={
        role:"assistant-rag",
        content:res.data
      }
      setConversation((conversation)=>[...conversation,assistantConv])
      setGeneratingChat(false)
    }
    useEffect(()=>{
      if(bottomRef.current){
        bottomRef.current.scrollIntoView()
      }
    },[conversation])
  return (
    <div>
      {/**CUSTOM NAVBAR */}
      <div className="flex z-10 justify-center w-screen h-20 bg-gray-100 dark:bg-slate-800 fixed top-0" style={{ alignItems: "center" }}>
          <img width="30px" height="30px" className="mr-2" src="https://i.postimg.cc/L8T1V5pD/image.png" onClick={() => navigator("/")}/>
          <h2 className="text-xl font-bold text-black font-title dark:text-gray-100">Conversate w Books</h2>
      </div>
      <button type="button" onClick={()=>window.history.back()} class="z-20 fixed left-2 md:left-20 top-6 bg-gray-400 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FaArrowLeft size={24} /></button>
      <div className="w-screen min-h-screen flex flex-col items-center justify-center bg-white p-4 pt-24 md:p-24 bg-pink-200 dark:bg-gray-900">
        <div className="flex flex-col-reverse   md:flex-row w-full justify-between">
            {/**CHATTER */}
            <div href="#" class="relative w-full md:w-2/5 min-h-[700px] bg-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            {/**CHATS */}
              {!ingesting && 
                <div className="absolute pb-20 top-0 flex p-6 flex-col w-full max-h-[700px] overflow-y-scroll">
                  
                    {conversation.map(a=>{
                        if(a.role=="assistant"){
                            return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                        }
                        if(a.role=="assistant-rag"){
                            return (<AssistantRag data={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                        }
                        else if(a.role=="user"){
                            return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                        }
                    })}
                    {generatingChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
                    <div ref={bottomRef}></div>
                </div>}
          
              {ingesting && <Loader text="Ingesting your document...."/>}
              {/**INPUT */}
              <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
                <textarea onKeyDown={(e)=>{
                  if(e.key==="Enter"){
                    e.preventDefault()
                    sendRAGQuery()
                  }
                }} type="text" rows={1} class=" block w-full md:w-4/5 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
                <div className="flex">
                  <button onClick={sendRAGQuery} class="h-full mx-1 text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><IoSendSharp /></button>
                </div>
              </div>
            </div>
            {/**Book BLOCK*/}
            <div href="#" class="relative mb-4 md:mb-0 p-2 flex flex-col items-center justify-start w-full md:w-3/5 min-h-[300px] md:min-h-[700px] bg-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
                {/**FILE UPLOADER */}
                <button type="button" onClick={sendDocument} class="z-20 absolute right-4 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><IoCloudUploadOutline size={16} /></button>
                <button type="button" onClick={ingestDocuments} class="z-20 absolute right-16 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><VscServerProcess size={16} /></button>

              {uploadedFile==null && <div class="flex items-center justify-center h-full w-full lg:w-1/3">
                <label
                  for="dropzone-file"
                  class="flex flex-col items-center justify-center w-full h-2/3 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 dark:hover:bg-blue-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600"
                >
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <RiUploadCloud2Line
                      size={32}
                      className="text-blue-500 dark:text-blue-400"
                    />
                    <p class="mb-2 text-sm text-blue-500 dark:text-blue-400">
                      <span class="font-semibold">Click to upload</span> or drag and
                      drop
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">
                      PDF, PPTX, CSV or TXT (MAX. 2MB)
                    </p>
                  </div>
                  <input id="dropzone-file" type="file" class="hidden" onChange={(e)=>{
                    console.log(e.target.files[0])
                    setUploadedFile(e.target.files[0])
                    setPdfUrl(URL.createObjectURL(e.target.files[0]))
                   
                  }}
                  />
                </label>
                </div>}
                {pdfUrl!=null &&<div className="w-full max-h-[300px] md:max-h-[700px] overflow-y-scroll">
                    {/** This pdfjs-dist@3.4.120 MUST MATCH THE VERSION */}
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                       <Viewer fileUrl={pdfUrl} />
                    </Worker> 
                  </div>}

            </div>       
        </div>
      </div>
    </div>
  );
}
