export const conversationRequest=async(messages,modelName="llama3.1")=>{
    if(modelName==null){
        return null
    }
    let retVal;
    const endpoint = "http://localhost:11434/api/chat"
    const requestBody={
        model:modelName,
        messages,
        stream:false
    }
  
    const request={
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(requestBody)
    }
    //const response = await(ollama_host+"/api/generate",request)
    //onsole.log(response)
    console.log(messages)
    console.log("generation Started")
   await fetch(endpoint,request)
    .then(response=>response.json())
    .then(data=>{
        console.log(data)
        retVal=data.message
        console.log(retVal)
        if(retVal.content==""){
            console.log("Shala")
            //Redo
            //conversationRequest(messages)
            //Making sure that the answer is not returned twice
            return
        }
        
    })
    console.log("generation ended")
    return retVal
    
}