import React,{useState} from 'react'
import Navbar from '../../Components/Navbar'
import AgeCard from '../../Components/Settings/AgeCard'
import GenderCard from '../../Components/Settings/GenderCard'
import { IoArrowBack,IoArrowForward } from "react-icons/io5";
import SubjectCard from '../../Components/Settings/SubjectCard'
import LanguageCard from '../../Components/Settings/LanguageCard'
import { subjects,subjectList } from '../../Data/Syllabus'
import LessonCard from '../../Components/Settings/LessonCard'
import { useNavigate } from 'react-router-dom'
export default function SetupCurriculumn() {
    const navigator=useNavigate()
    const [currentStage,setCurrentStage]=useState(1)
    const [age,setAge]=useState(1)
    const [gender,setGender]=useState("Girl")
    const [preferedLanguage,setPreferedLanguage]=useState("English")
    const [subject,setSubject]=useState("🥼🧪Science🔬🧬")
    const [lesson,setLesson]=useState("Physics")

    const ageSetter=(val)=>{
      localStorage.setItem("sal-age",val)
      setAge(val)
    }
    const genderSetter=(val)=>{
      setGender(val)
      localStorage.setItem("sal-gender",val)
    }
    const subjectSetter=(val)=>{
      localStorage.setItem("sal-subject",val)
      setSubject(val)
    }
    const languageSetter=(val)=>{
      setPreferedLanguage(val)
      localStorage.setItem("sal-lan",val)
    }
    const lessonSetter=(val)=>{
      setLesson(val)
      localStorage.setItem("sal-lesson",val)
    }

   
  return (
    <div className='dark:bg-gray-900 w-screen min-h-screen '>
       <Navbar/>
       <div className='w-full flex flex-col'>
          {/*  PROGRESS */}
          <div class="w-full mt-20 bg-gray-200 h-2.5 dark:bg-gray-700">
              <div class="bg-amber-600 h-2.5" style={{width:`${(currentStage/5)*100}%`}}></div>
          </div>
          {/** AGE */}
          {currentStage===1 &&  <div>
            <div className='w-full flex flex-col items-center justify-center bg-white dark:bg-gray-900'>
            <h2 class="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">My child is </h2>
              <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'  >
                  <AgeCard id={1} title="0-5" active={age} ageSetter={ageSetter}/>
                  <AgeCard id={2} title="6-10" active={age} ageSetter={ageSetter}/>
                  <AgeCard id={3} title="10-15" active={age} ageSetter={ageSetter}/>
                  <AgeCard id={4} title="15+" active={age} ageSetter={ageSetter}/>
              </div>
              <div class="mt-10 inline-flex rounded-md shadow-sm" role="group">
              
                <button onClick={()=>setCurrentStage(2)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white">Next Step<IoArrowForward size={24}className='ml-2'/></button>
              </div>
            </div>
            
              
          </div> }       
          {/** GENDER */}
          {currentStage===2 &&  <div>
            <div className='w-full flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
              <h2 class="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">My child is</h2>
                <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'  >
                    <GenderCard title="Girl" active={gender==="Girl"} genderSetter={genderSetter}/>
                    <GenderCard title="Boy" active={gender==="Boy"} genderSetter={genderSetter}/>
                </div>
                <div class="mt-10 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={()=>setCurrentStage(1)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white"><IoArrowBack size={24} className='mr-2'/> Previous Step</button>
                  <button onClick={()=>setCurrentStage(3)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white">Next Step<IoArrowForward size={24}className='ml-2'/></button>
                </div>
            </div>        
          </div> }       
          {/** LKANGUAGE */}
          {currentStage===3 &&  <div>
            <div className='w-full flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
              <h2 class="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">My child knows...</h2>
                <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'  >
                    <LanguageCard title="English" active={preferedLanguage==="English"} languageSetter={languageSetter}/>
                    <LanguageCard title="Bengali" active={preferedLanguage==="Bengali"} languageSetter={languageSetter}/>
                    <LanguageCard title="French" active={preferedLanguage==="French"} languageSetter={languageSetter}/>
                    <LanguageCard title="Spanish" active={preferedLanguage==="Spanish"} languageSetter={languageSetter}/>
                    <LanguageCard title="Hindi" active={preferedLanguage==="Hindi"} languageSetter={languageSetter}/>
                    <LanguageCard title="Arabic" active={preferedLanguage==="Arabic"} languageSetter={languageSetter}/>
                </div>
                <div class="mt-10 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={()=>setCurrentStage(2)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white"><IoArrowBack size={24} className='mr-2'/> Previous Step</button>
                  <button onClick={()=>setCurrentStage(4)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white">Next Step<IoArrowForward size={24}className='ml-2'/></button>
                </div>
            </div>        
          </div> }       
          {/** SUBJECT */}
          {currentStage===4 &&  <div>
            <div className='w-full flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
              <h2 class="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">I want to teach my child...</h2>
                <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'  >
                  {subjectList.map(a=><SubjectCard title={a} active={subject===a} subjectSetter={subjectSetter}/>)}
                </div>
                <div class="mt-10 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={()=>setCurrentStage(2)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white"><IoArrowBack size={24} className='mr-2'/> Previous Step</button>
                  <button onClick={()=>setCurrentStage(5)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white">Next Step<IoArrowForward size={24}className='ml-2'/></button>
                </div>
            </div>        
          </div> }       
          {/** LESSONS */}
          {currentStage===5 &&  <div>
            <div className='w-full flex flex-col items-center justify-center bg-white  dark:bg-gray-900'>
            <h2 class="mb-8 text-left md:text-left text-3xl md:text-6xl tracking-tight font-bold text-gray-900 dark:text-white">I want to ...</h2>
                <div className=' grid grid-cols-1 md:grid-cols-2 gap-6'>
                  {subjects[subject]["lessons"].map(a=><LessonCard title={a} active={lesson===a} lessonSetter={lessonSetter}/>)}
                </div>
                <div class="mt-10 inline-flex rounded-md shadow-sm" role="group">
                  <button onClick={()=>setCurrentStage(4)} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white"><IoArrowBack size={24} className='mr-2'/> Previous Step</button>
                  <button onClick={()=>navigator("/curriculumn-congrats")} type="button" class="flex items-center px-6 py-4 text-xl font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-amber-700 focus:z-10 focus:ring-2 focus:ring-amber-700 focus:text-amber-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-amber-500 dark:focus:text-white">Complete</button>
                </div>
            </div>        
          </div> }
        </div>       
    </div>
  )
}
