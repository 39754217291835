import React from 'react'
import {motion} from 'framer-motion'
import { MdDelete } from 'react-icons/md'
import SessionCard from './SessionCard'
export default function SessionPanel() {
  return (
    <div className='relative w-full p-6 shadow-lg rounded-lg border'>
        <p className='absolute top-4 right-4 text-xl rounded text-gray-200 bg-emerald-700 px-2 py-1 font-semibold'>241 Sessions</p>
        <h4 className='text-lg font-semibold text-emerald-700 mb-4'>My Sessions</h4>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <SessionCard/>
            <SessionCard/>
            <SessionCard/>
        </div>
        
    </div>
  )
}
