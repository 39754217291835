import React,{useState} from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
export default function ExpertProfiling() {
    const navigate=useNavigate()
    const [formData,setFormData]=useState({})
    const [selectedGender,setSeletedGender]=useState(null)
    const [selectedOccupation,setSeletedOccupation]=useState(null)
    const [selectedTopic,setSeletedTopic]=useState(null)
    const [selectedDegree,setSeletedDegree]=useState(null)
    const [selectedLlmUsage,setSeletedLlmUsage]=useState(null)
    const genderOptions=["Male","Female","Other","Rather not say"]
    const occupationOptions=["Student","Teacher","Researcher","Other"]
    const topicOptions=["Math","Physics","Chemistry","Biology"]
    const degreeOptions=["Secondary School","Higher Secondary School","Undergraduate","Masters"]
    const llmUsageOptions=["Daily","Monthly","Yearly","Never Used"]
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    const saveProfile=async()=>{
        console.log(formData)
        navigate('/dataset-generator/create-project')
    }
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-start'>
        <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex items-center justify-start p-4 md:p-6 bg-white'>
            <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
                <FaAngleLeft/>
            </motion.button>
           <h2 className='ml-4 font-semibold text-md'>S.A.L. Dataset Generator</h2>
            
        </div>
        <div className='mt-20 w-full max-w-7xl px-12'>
            <h2 className='text-3xl font-bold text-emerald-700'>Expert Profiling✍</h2>
            <p className='text-sm font-semibold text-gray-700'>Answer the following questions before you start generating answers</p>
        </div>
        <div className="space-y-4 max-w-7xl p-12 ">
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Your Name
                </label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
            
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Gender
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {genderOptions.map(a=>(
                        <button onClick={()=>setSeletedGender(a)} className={`p-4 border rounded-md shadow ${selectedGender===a && "bg-emerald-700 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Occupation
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {occupationOptions.map(a=>(
                        <button onClick={()=>setSeletedOccupation(a)} className={`p-4 border rounded-md shadow ${selectedOccupation===a && "bg-emerald-700 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Degree
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {degreeOptions.map(a=>(
                        <button onClick={()=>setSeletedDegree(a)} className={`p-4 border rounded-md shadow ${selectedDegree===a && "bg-emerald-700 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    LLM Usage
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {llmUsageOptions.map(a=>(
                        <button onClick={()=>setSeletedLlmUsage(a)} className={`p-4 border rounded-md shadow ${selectedLlmUsage===a && "bg-emerald-700 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Topic
                </label>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                    {topicOptions.map(a=>(
                        <button onClick={()=>setSeletedTopic(a)} className={`p-4 border rounded-md shadow ${selectedTopic===a && "bg-emerald-700 text-white"}`}>
                            <p className='text-sm font-semibold text-center'>{a}</p>
                        </button>
                    ))}
                </div>
            </div>
            <div>
                <label className="block text-lg text-gray-700 font-semibold">
                    Learning Period (For this topic)
                </label>
                <input
                    type="number"
                    name="learning_period"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Learning Period (Years)"
                    className="w-full text-sm p-3 border border-gray-300 rounded-lg"
                />
            
            </div>
            <div>
                <button onClick={saveProfile} className='px-4 py-2 rounded border border-emerald-700 text-emerald-700 text-md font-semibold hover:bg-emerald-700 hover:text-white'>Save Profile</button>
            </div>
                  
        </div>
    </div>
  )
}
