import React,{useState,useEffect, useRef} from 'react'
import {motion} from 'framer-motion'
import { FaAngleLeft, FaPlane } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CreateProjectModal from '../../Components/DatasetGenerator/CreateProjectModal'
import ProjectCard from '../../Components/DatasetGenerator/ProjectCard'
import { MdAdd } from 'react-icons/md'
import SystemPromptModal from '../../Components/DatasetGenerator/SystemPromptModal'
import { BsEye } from 'react-icons/bs'
import axios from 'axios'
import PlaygroundModal from '../../Components/DatasetGenerator/PlaygroundModal'
export default function CreateQA() {
    const questionAreaRef=useRef(null)
    const answerAreaRef=useRef(null)
    const navigate=useNavigate()
    const [loading,setLoading]=useState(false)
    const [selectedModel,setSelectedModel]=useState("llama3:latest")
    
    const [openSystemPromptModal,setOpenSystemPromptModal]=useState(false)
    const [openPlaygroundModal,setOpenPlaygroundModal]=useState(false)
    
    const [currentQuestion,setCurrentQuestion]=useState("")
    const [currentAnswer,setCurrentAnswer]=useState("")
    const [currentInputTokens,setCurrentInputTokens]=useState(null)
    const [currentOutputTokens,setCurrentOutputTokens]=useState(null)

    const [conversation,setConversation]=useState([])
    const [addingQuestion,setAddingQuestion]=useState(false)
    const [addingAnswer,setAddingAnswer]=useState(false)
    const [generatingQuestion,setGeneratingQuestion]=useState(false)
    const [generatingAnswer,setGeneratingAnswer]=useState(false)
    const [questionPrompt,setQuestionPrompt]=useState("You are an agent that users generate questions on various topics. You will only generate a single line question and nothing else on the given question-answer history. Do not create anything else. Just create one single line question. If you create anything else you will be penalized 500 dollars.")
    const [answerPrompt,setAnswerPrompt]=useState("You are an agent that users generates answerst to questions on various topics.Only create the answer. Do not create anything else. If you create anything else you will be penalized 500 dollars.")
    const [ollamaModels,setOllamaModels]=useState([])
    const changeQuestionPrompt=(value)=>{
      setQuestionPrompt(value)
    }
    const changeAnswerPrompt=(value)=>{
      setAnswerPrompt(value)
    }
    const addQuestion=()=>{
        const obj={
          type:"question",
          content:currentQuestion,
          model:selectedModel,
          inputTokenCount:currentInputTokens,
          outputTokenCount:currentOutputTokens,
        }
        //console.log([...conversation,obj])
        setConversation([...conversation,obj])
        setAddingQuestion(false)
        setCurrentQuestion("")
    }
    const addAnswer=()=>{
        const obj={
          type:"answer",
          content:currentAnswer,
          model:selectedModel,
          inputTokenCount:currentInputTokens,
          outputTokenCount:currentOutputTokens,
        }
        setConversation([...conversation,obj])
        setCurrentAnswer("")
        setAddingAnswer(false)
    }
    const createQuestionWithOllama=async(modelName="llama3")=>{
      if(modelName==null){
        return null
      }
      setGeneratingQuestion(true)
      const convo=[{   
        role:"system",
        content:questionPrompt
      }]
      conversation.map(a=>{
        convo.push({
          role:a["type"]==="question"?"assistant":"user",
          content:a["content"]
        })
      })
      let retVal;
      //console.log(convo)
      //console.log("Start")
      const endpoint = "http://localhost:11434/api/chat"
      const res = await axios.post(endpoint,{
        model:modelName,
        messages:convo,
        stream:false
      })
      console.log(res.data)
      //console.log("END")
      setCurrentInputTokens(res.data.prompt_eval_count)
      setCurrentOutputTokens(res.data.eval_count)
      setCurrentQuestion(res.data.message.content)
      setGeneratingQuestion(false)
      return retVal
      
    }
    const createAnswerWithOllama=async(modelName="llama3")=>{
      if(modelName==null){
        return null
      }
      setGeneratingAnswer(true)
      const convo=[{   
        role:"system",
        content:answerPrompt
      }]
      console.log(conversation)
      conversation.map(a=>{
        convo.push({
          role:a["type"]==="question"?"user":"assistant",
          content:a["content"]
        })
      })
      let retVal;
      const endpoint = "http://localhost:11434/api/chat"
      //console.log(convo)
      //console.log("Starting Generation")
      const res = await axios.post(endpoint,{
        model:modelName,
        messages:convo,
        stream:false
      })
      setGeneratingAnswer(false)
      console.log(res.data)
      setCurrentAnswer(res.data.message.content)
      setCurrentInputTokens(res.data.prompt_eval_count)
      setCurrentOutputTokens(res.data.eval_count)
      return retVal
    }
    const getAllModels=async()=>{
      setLoading(true)
      const res=await axios.get("http://localhost:11434/api/tags")
      //console.log(res.data)
      setLoading(false)
      setOllamaModels(res.data.models)
    }
    useEffect(() => {
      getAllModels()
    }, []);
    useEffect(() => {
      const textarea = questionAreaRef.current;
      if(textarea){
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }, [currentQuestion]);
    useEffect(() => {
      const textarea = answerAreaRef.current;
      if(textarea){
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }, [currentAnswer]);
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Generate QA</h2>

      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
          <h2 className='text-left text-3xl font-bold '>Create <span className='text-emerald-700 '>Question / Answer</span></h2>
          <p className='text-sm font-gray-600'>Directly create questions and answers with LLM assistance</p>
      </div>
      <button onClick={()=>{setOpenSystemPromptModal(true)}} className='z-10 fixed top-3 md:top-20 right-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><BsEye className='text-xl'/>System Prompts</button>
      <button onClick={()=>{setOpenPlaygroundModal(true)}} className='z-10 fixed top-3 md:top-20 left-4 flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><FaPlane className='text-xl'/>Playground</button>
      {openSystemPromptModal && <SystemPromptModal closeModal={()=>setOpenSystemPromptModal(false)} questionPrompt={questionPrompt} answerPrompt={answerPrompt} changeQuestionPrompt={changeQuestionPrompt} changeAnswerPrompt={changeAnswerPrompt}/>}
      {openPlaygroundModal && <PlaygroundModal closeModal={()=>setOpenPlaygroundModal(false)} ollamaModels={ollamaModels}/>}
      <div className='w-full max-w-7xl flex flex-col items-start justify-start my-4'>
          <div className='w-full flex flex-col md:flex-row items-end md:items-center justify-end space-x-4'>
            <p className='text-sm font-semibold text-gray-600'>Selected Model</p>
            <select className='text-sm p-1 outline-none' value={selectedModel} onChange={(e)=>setSelectedModel(e.target.value)}>
              <option value="gpt-4o">GPT 4-o</option>
              <option value="claude-3.5-opus">Claude 3.5 Opus</option>
              {!loading && ollamaModels.map(a=>(
                <option value={a.model}>{a.name}</option>
              ))}
            </select>  
          </div>
          
          {conversation.length>0 && <div className='bg-gray-100 py-4 px-2 mt-2 rounded w-full mb-6'>
            {conversation.map(a=>(<div>
              {a.type==="question" && <div className='mb-1'>
                <p className='px-2 text-md font-bold border-l-4 border-gray-800'>Question</p>
                <p className='px-2 text-sm text-gray-600 border-l-4 border-gray-800'>{a.content}</p>
              </div>}
              {a.type==="answer" && <div className='mb-1'>
                <p className='px-2 text-md font-bold border-l-4 text-emerald-700 border-emerald-700'>Answer</p>
                <p className='px-2 text-sm text-gray-600 border-l-4 border-emerald-700'>{a.content}</p>
              </div>}

            </div>))}
            <div className='w-full flex mt-2 flex-col md:flex-row items-end md:items-center justify-end space-x-4'>
              <button onClick={()=>{console.log(conversation)}} className='flex items-center space-x-2 text-xs font-semibold px-4 py-2 bg-emerald-700 rounded text-white hover:bg-emerald-800'><MdAdd className='text-xl'/>Add to dataset</button> 
            </div>
          </div>}
          {!addingQuestion && (conversation[conversation.length-1]?.type==="answer" || conversation.length===0) && <button onClick={()=>setAddingQuestion(true)} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Create Question</button>}
          {addingQuestion && !addingAnswer && <div className='w-full'>
            <label className="block text-sm text-gray-700 font-semibold">
              Question
            </label>
            {generatingQuestion && <p className='text-xs my-4'>Generating question...</p>}
            {!generatingQuestion && <textarea
                ref={questionAreaRef}
                type="text"
                name="name"
                value={currentQuestion}
                onChange={(e)=>setCurrentQuestion(e.target.value)}
                placeholder="Enter Your Question"
                className="w-full text-sm p-3 border border-gray-300 rounded"
            />}
            <div className='w-full flex justify-between'>
              {(conversation.length>1) && <button onClick={()=>createQuestionWithOllama()} className='px-4 py-2 font-semibold text-sm border text-orange-500 border-orange-500 hover:text-white hover:bg-orange-500'>Generate Question</button>}
              <button onClick={addQuestion} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Add Question</button>
            </div>
          </div>}
          {!addingAnswer && (conversation[conversation.length-1]?.type==="question") && <button onClick={()=>setAddingAnswer(true)} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Create Answer</button>}
          {addingAnswer && !addingQuestion && <div className='w-full'>
            <label className="block text-sm text-gray-700 font-semibold">
              Answer
            </label>
            {generatingAnswer && <p className='text-xs my-4'>Generating answer...</p>}
            {!generatingAnswer && <textarea
                ref={answerAreaRef}
                type="text"
                name="name"
                value={currentAnswer}
                onChange={(e)=>setCurrentAnswer(e.target.value)}
                placeholder="Enter Your Answer"
                className="w-full text-sm p-3 border border-gray-300 rounded"
            />}
            <div className='w-full flex justify-between'>
              {(conversation.length>0) && <button onClick={()=>createAnswerWithOllama()} className='px-4 py-2 font-semibold text-sm border text-orange-500 border-orange-500 hover:text-white hover:bg-orange-500'>Generate Answer</button>}
              <button onClick={addAnswer} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'>Add Answer</button>
            </div>
          </div>}
          
      </div>
  </div>
    )
}
