import React,{useState} from 'react'
import {  FaAngleDown, FaAngleUp } from 'react-icons/fa'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { MdAdd } from 'react-icons/md'
const ProjectSubcard=({project})=>{
    const navigate=useNavigate()
    return (
        <motion.div whileHover={{translateY:"-5px"}} onClick={()=>navigate("")} className='p-4 cursor-pointer rounded border hover:border-emerald-700'>
            <div className='flex justify-end mb-2'>
                <h4 className='text-sm text-gray-600'><span className='font-semibold px-2 py-1 rounded  bg-gray-300 text-gray-600'>12/23/2003</span></h4>
            </div>
            <p className='text-md font-semibold text-right'>Physics Magnetism with images</p>
           
            <h4 className='text-sm mt-2 text-gray-600 flex items-center'><span className='font-semibold text-xl text-emerald-700 mr-2'>321</span> Datapoints</h4>
            <p className='text-sm'></p>
        </motion.div>
    )
}
const ProjectNew=({project})=>{
    const navigate=useNavigate()
    return (
        <motion.div whileHover={{translateY:"-5px"}} onClick={()=>navigate("")} className='p-4 cursor-pointer rounded border hover:border-emerald-700 flex items-center flex-col justify-center'>
            <MdAdd className='text-5xl text-gray-500 p-2 rounded-full bg-gray-300'/>
            <h2 className='font-semibold mt-2 text-gray-500'>Create New Dataset</h2>
        </motion.div>
    )
}
export default function ProjectCard({data}) {
    const [showDetails,setShowDetails]=useState(false)
    return (
        <div className='p-6 border rounded-md shadow my-2 flex flex-col'>
            <div className='w-full flex items-center justify-between'>
                <div className='flex flex-col items-start'>
                    <h3 className='p-1 px-2 rounded my-2 bg-amber-200 text-amber-700 text-xs font-semibold'>{data.topic}{data.subtopic&&` > ${data.subtopic}`}</h3>
                    <h6 className='text-md font-semibold'>{data.title}</h6>
                    <h6 className='text-gray-600 text-sm '>{data.subtitle}</h6>
                </div>
                <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} onClick={()=>setShowDetails(!showDetails)} className='text-2xl p-2 rounded-full border border-gray-300 hover:bg-gray-100'>
                    {showDetails ? <FaAngleUp/> : <FaAngleDown/>}
                </motion.button>
            </div>
            {showDetails && <motion.div initial={{scaleY:0}} animate={{scaleY:1}} transition={{duration:0.3}} >
                <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-emerald-700'>Overview</h5>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8'>
                    <div className='flex flex-col-reverse  md:flex-col items-center'>
                        <h5 className='w-full text-center font-semibold text-4xl mt-2 md:mt-0 mb-2 px-4 py-2 rounded text-emerald-700 shadow-md border-2 border-emerald-700'>3</h5>
                        <p className='text-sm text-center text-gray-500 font-medium'>Total Datasets</p>
                    </div>
                    <div className='flex flex-col-reverse  md:flex-col items-center'>
                        <h5 className='w-full text-center font-semibold text-4xl mt-2 md:mt-0 mb-2 px-4 py-2 rounded text-emerald-700 shadow-md border-2 border-emerald-700'>{data.generated_datapoint}</h5>
                        <p className='text-sm text-center text-gray-500 font-medium'>Total Datapoints</p>
                    </div>
                    <div className='flex flex-col items-center'>
                        <h5 className='w-full text-center font-semibold text-4xl mb-2 px-4 py-2 rounded text-emerald-700 shadow-md border-2 border-emerald-700'>{data.estimated_cost}</h5>
                        <p className='text-sm text-center text-gray-500 font-medium'>Estimated Cost</p>
                    </div>
                    <div className='flex flex-col items-center'>
                        <h5 className='w-full text-center font-semibold text-4xl mb-2 px-4 py-2 rounded text-emerald-700 shadow-md border-2 border-emerald-700'>2</h5>
                        <p className='text-sm text-center text-gray-500 font-medium'>Collaborators</p>
                    </div>
                </div>
               
            
                <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-emerald-700'>Datasets</h5>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    <ProjectSubcard/>
                    <ProjectSubcard/>
                    <ProjectSubcard/>
                    <ProjectSubcard/>
                    <ProjectNew/>
                </div>


                <hr className='my-4'/>
                <h5 className='mt-4 mb-2 font-bold text-lg text-emerald-700'>Collaborators</h5>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    
                </div>
            </motion.div>}
        </div>
    )
}
