import React from 'react'
import {motion} from 'framer-motion'
export default function ParticipantCard({avatar="https://i.postimg.cc/c4qT8QR9/12.png",name="Unknown User",institute="unknown",active=false,added=false}) {
  return (
    <div className='relative flex items-center bg-gray-200 rounded shadow-lg px-4 py-1 my-1'>
        <img alt="avatar" className="w-8 h-8 p-1 mr-1 rounded-full bg-gray-200" src={avatar}/>
        <p className='text-xs font-semibold px-2'>{name}<span className='font-normal'> | {institute} | </span></p>
        {!added && <button className='text-xs font-semibold mx-2 px-2 py-1 rounded text-white bg-green-500 hover:bg-green-600'>Add Friend</button>}
        {added && <button className='text-xs font-semibold mx-2 px-2 py-1 rounded text-white bg-red-500 hover:bg-red-600'>Remove Friend</button>}
        {active && <motion.div initial={{opacity:0}} animate={{opacity: 1}} transition={{repeat:Infinity,duration:1,ease:"circInOut"}} className='absolute top-2 right-2 w-2 h-2 rounded-full bg-green-500'></motion.div>}
        {!active && <div className='absolute top-2 right-2 w-2 h-2 rounded-full bg-red-500'></div>}
        
    </div>
  )
}
