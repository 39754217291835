import React from 'react'
import {motion} from 'framer-motion'
import { FaEye } from 'react-icons/fa'


export default function ProfileSessionCard({openSession,active=false}) {
  return (
    <div className='w-full flex items-center justify-between my-2 rounded-lg shadow-lg p-4 px-6 border'>
        <div className='flex flex-col items-start'>
            <div className='relative'>
            {active && <motion.div initial={{opacity:0}} animate={{opacity: 1}} transition={{repeat:Infinity,duration:1,ease:"circInOut"}} className='absolute top-2 left-2 w-2 h-2 rounded-full bg-white'></motion.div>}
            {active && <p className='text-xs px-4 rounded-md text-white py-1 mb-2 bg-green-500 pl-6'>Active</p>}
            {!active && <p className='text-xs px-4 rounded-md text-white py-1 mb-2 bg-gray-700'>Closed</p>}
            </div>
            <h5 className='text-xs text-indigo-500 font-bold'>Session ID </h5>
            <h5 className='text-md font-semibold mb-2'>asdfadsf-asdfasdf-sadfsadf-sadxcv</h5>
            <p className='text-xs'>25/7/2024</p>
        </div>
        <div className='flex space-x-2'>
            {/* <div className='flex flex-col items-center justify-between'>
                <p className='text-xs font-semibold'>Public</p>
                <Checkbox className='w-6 h-6'/>
            </div> */}
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-green-500 text-xl rounded border border-green-500 hover:bg-green-600 hover:text-white'><FaEye/></motion.button>  
            {/* <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-gray-500 text-xl rounded border border-gray-500 hover:bg-gray-600 hover:text-white'><MdEdit/></motion.button>  
            <motion.button whileHover={{scale:1.05}} whileTap={{scale:0.95}} className='p-2 text-red-500 text-xl rounded border border-red-500 hover:bg-red-600 hover:text-white'><MdDelete/></motion.button> */}
        </div>
    </div>
)
}
