import React, { useEffect, useRef, useState } from "react";
import { BsChatDots, BsChatDotsFill, BsChatHeart, BsSave, BsSave2 } from "react-icons/bs";
import { IoSendSharp } from "react-icons/io5";
import { MdOutlineSave } from "react-icons/md";
import {BiReset} from 'react-icons/bi'
import {FiDownloadCloud} from 'react-icons/fi'
import AssistantChat from "../../Components/Chatter/AssistantChat";
import UserChat from "../../Components/Chatter/UserChat";
import { conversationRequest } from "../../Models/ConversationAgent";
import { createSplitDocuemnts } from "../../Models/RAG/langchain";
import { FaArrowLeft } from "react-icons/fa";
import AssistantChatLong from "../../Components/Chatter/AssistantChatLong";
import UserChatLong from "../../Components/Chatter/UserChatLong";
import { GetAvailableModels } from "../../Models/GetAvailableModels";
import LoaderFullPage from "../../Components/LoaderFullPage";

//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
// https://i.postimg.cc/c4qT8QR9/12.png
// https://i.postimg.cc/B6kppFdv/13.png
// https://i.postimg.cc/d3CBx1Yj/14.png
// https://i.postimg.cc/506p8gKG/15.png
// https://i.postimg.cc/mrd8cJjC/16.png
// https://i.postimg.cc/8kft5y6f/17.png
// https://i.postimg.cc/tJnP4mvq/18.png
export default function ChatAgent() {
    const bottomRef=useRef()
    const [model,setModel]=useState(null)
    const [loadingModels,setLoadingModels]=useState(true)
    const [availableModels,setAvailableModels]=useState(null)
    const [currentUserChat,setCurrentUserChat]=useState(null)
    const [generatingChat,setGeneratingChat]=useState(false)
    const [conversation,setConversation]=useState([
      {   
        role:"system",
        content:"You are an agent that helps users learn things. The user may ask you questions or tell you to explain certain things. Try to answer appropriately. If you give empty answers, you will be penalized 100 dollars. If you answer in markdown you will get 100 dollar reward"
      },
      {   
        role:"assistant",
        content:"Hello there I'm your assistant here to help you with anythng that you need. Please feel free to ask me any general questions about anything that you might want to know. I am here to assist you in any way that I can.I understand that I cannot give empty answers, so I will always make sure to give you a helpful response or redirect you to a source that can help you.Let me know how I can assist you today!"
      }
  ])
    const createConversation=async()=>{
        const userConvBody={
            role:"user",
            content:currentUserChat
        }
        setConversation((conversation)=>[...conversation,userConvBody])
        const conv=[...conversation,userConvBody]
        generateAnswer(conv)
        setGeneratingChat(true)
        setCurrentUserChat("")

    }
    const generateAnswer=async(conv)=>{
      const agentConvBody=await conversationRequest(conv,model)
      if(agentConvBody==null){
        alert("Choose A model first!")
        return
      }
      setConversation((conversation)=>[...conversation,agentConvBody])
      setGeneratingChat(false)
    }
   
    const saveConversation=()=>{
        localStorage.setItem("sal-agent-conversation",JSON.stringify(conversation))
        alert("Your conversation has been saved!")
    }
    const loadConversation=()=>{
        setConversation(JSON.parse(localStorage.getItem("sal-agent-conversation")))
        alert("Your previous conversation has been loaded")
    }
    const resetConversation=()=>{
        alert("Your conversation will be re-set!")
        setConversation([
            {
                role:"assistant",
                content:"Hello there I'm your assistant here to help you with anythng that you need. Please feel free to ask me any general questions about anything that you might want to know"
            }
        ])
    }

    const getAvailableModels=async()=>{
      let mdls=[]
      const endpoint = "http://localhost:11434/api/tags"
      await fetch(endpoint)
      .then(response=>response.json())
      .then(data=>{
          //console.log(data)
          data.models.map(element => {
            mdls.push(element.name)
          });
          
      }).catch(e=>{
          console.log(e)
      })
      setAvailableModels(mdls)
      setLoadingModels(false)
  }
    useEffect(()=>{
      getAvailableModels()
    },[])
    useEffect(()=>{
      if(bottomRef.current){
        bottomRef.current.scrollIntoView()
      }
    },[conversation])
  if(loadingModels) return <LoaderFullPage text="Loading Available Local Models"/>
  return (
    <div>

        {/**CUSTOM NAVBAR */}
        <div className="flex z-10 justify-center w-screen h-20 bg-gray-100 dark:bg-slate-800 fixed top-0" style={{ alignItems: "center" }}>
            <img width="30px" height="30px" className="mr-2" src="https://i.postimg.cc/L8T1V5pD/image.png" onClick={() => navigator("/")}/>
            <h2 className="text-xl font-bold text-black font-title dark:text-gray-100">Conversational Agent</h2>
        </div>
        <button type="button" onClick={()=>window.history.back()} class="z-20 fixed left-2 md:left-20 top-6 bg-gray-400 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FaArrowLeft size={24} /></button>
      <div className="w-screen min-h-screen flex flex-col items-center justify-center bg-white p-4 md:p-24 bg-pink-200 dark:bg-gray-900">

        {/** BUTTONS */}
        <div class="hidden md:inline-flex rounded-md shadow-sm mb-6" role="group">
        {availableModels!=null && <select onChange={e=>setModel(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose A Model</option>
          {availableModels.map(a=>(<option value={a}>{a}</option>))}
        </select>}
          
        </div>

        <div href="#" class="relative w-full min-h-[700px] bg-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
          {/**CHATS */}
          <div className="absolute pb-20 top-0 flex p-6 flex-col w-full max-h-[700px] overflow-y-scroll">
            <button type="button" onClick={saveConversation} class="z-20 absolute right-4 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><MdOutlineSave size={16} /></button>
            <button type="button" onClick={resetConversation} class="z-20 absolute right-16 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiReset size={16} /></button>
            <button type="button" onClick={loadConversation} class="z-20 absolute right-28 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FiDownloadCloud size={16} /></button>
              {conversation.map(a=>{
                  if(a.role=="assistant"){
                      return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                  }
                  else if(a.role=="user"){
                    return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                  }
                  
              })}
              {generatingChat && <AssistantChat text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
              <div ref={bottomRef}></div>
          </div>
          {/**INPUT */}
          <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
                <textarea onKeyDown={(e)=>{
                  if(e.key==="Enter"){
                    e.preventDefault()
                    createConversation()
                  }
                }} type="text" rows={1} class=" block w-full md:w-4/5 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
                <div className="flex">
                  <button onClick={createConversation} class="h-full mx-1 text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><IoSendSharp /></button>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
