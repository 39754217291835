import React, { useState } from 'react'
import MDEditor from '@uiw/react-md-editor'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
export default function AssistantRag({data,avatar}) {
    const [showSources,setShowSources]=useState(false)
  return (
    <div className="flex my-2">
        <img className="w-12 h-12 p-1 mr-6 rounded-full bg-gray-200" src={avatar}/>
        <div href="#" class="w-4/5  p-4   border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            {/*<pre style={{whiteSpace:"pre-wrap"}}>{text}</pre>*/}
            <MDEditor.Markdown className='dark:hidden' source={data.result} style={{ background: "transparent",color:"black" }} />
            <MDEditor.Markdown className='hidden dark:block' source={data.result} style={{ background: "transparent", }} />
            {showSources && <div>
                <button onClick={()=>setShowSources(false)} type="submit" class="text-white mt-4 bg-gray-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"><MdArrowDropUp size={24}/></button>
                {data.source_documents.map((a,idx)=>(
                    <div className='bg-gray-100 rounded full m-2 p-2 text-wrap'>
                        <p className='font-bold text-sm text-orange-500'>Source ({idx+1}/5)</p>
                        <p className='text-xs text-justify'>{a.content}</p>
                        {a.metadata["page"]!=null &&<p className='flex flex-col items-start border border-gray-500 p-1 text-xs my-2'>
                            <span className='font-bold'>Page Number : <span className='text-orange-500'>{a.metadata["page"]+1}/{a.metadata["total_pages"]}</span></span>
                            {a.source}
                        </p>}
                    </div>
                ))}
                </div>}
            {!showSources && <button onClick={()=>setShowSources(true)} type="submit" class="text-white mt-4 bg-gray-700 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"><MdArrowDropDown size={24}/></button>}
        </div>
        
    </div>
  )
}
// <div class="w-full relative overflow-x-auto">
//                 <table class="max-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//                     <thead class="max-w-full text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                         <tr >
//                             <th scope="col" class="px-1 py-3 max-w-1/2">
//                                 Source
//                             </th>
//                             <th scope="col" class="px-1 py-3 max-w-1/2">
//                                 Content
//                             </th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data.source_documents.map(a=>(<tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
//                             <th scope="row" class="px-1 py-2 max-w-1/2 text-wrap font-medium text-gray-900 whitespace-nowrap dark:text-white">
//                                 {a.content}
//                             </th>
//                             <td class="max-w-1/2 px-1 py-2">
//                                 {a.source}
//                             </td>
//                         </tr>))}
                        
//                     </tbody>
//                 </table>
//             </div>