import React, { useState } from 'react'
import {motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaAngleLeft, FaEdit, FaFile } from 'react-icons/fa'
import { MdDone, MdEdit } from 'react-icons/md'

const DatasetDetailsForm=({data})=>{
  const [title,setTitle]=useState(data.title)
  const [editingTitle,setEditingTitle]=useState(false)
  return(<div className='w-full space-y-2'>
    <div className='w-full flex items-end'>
      <div className='w-full '>
        <label className="block text-sm text-gray-700 ">
        Dataset Title
        </label>
        {editingTitle && <input
            type="text"
            name="name"
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            placeholder="Enter Your Prompt"
            className="w-full text-sm p-3 border border-gray-300 rounded"
        />}
        {!editingTitle && <p className='font-semibold py-1 rounded'>{title}</p>}
      </div>
      {!editingTitle && <button onClick={()=>{setEditingTitle(true)}} className='p-3 ml-2 rounded font-semibold text-xl border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'><MdEdit/></button>}
      {editingTitle && <button onClick={()=>{setEditingTitle(false)}} className='p-3 ml-2 rounded font-semibold text-xl border text-emerald-700 border-emerald-700 hover:text-white hover:bg-emerald-700'><MdDone/></button>}
    </div>
    <div className='w-full '>
      <label className="block text-sm text-gray-700 ">
      Project Title
      </label>
      <p className='font-semibold py-1 rounded'>{data.projectTitle}</p>
    </div>
    <div className='w-full '>
      <label className="block text-sm text-gray-700 ">
      Total Datapoints
      </label>
      <p className='font-semibold py-1 rounded'>{data.dataPoints}</p>
    </div>
    <div className='w-full '>
      <label className="block text-sm text-gray-700 ">
      Estimated Cost
      </label>
      <p className='font-semibold py-1 rounded'>${data.estimatedCost}</p>
    </div>
  </div>)
}
const DatasetRow=({data})=>{
  const [showDetails,setShowDetails]=useState(false)
  return(
  <div onClick={()=>setShowDetails(!showDetails)} className='w-full p-4 cursor-pointer bg-white border hover:border-emerald-700'>
    <div className='w-full flex'>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>First Question</p><p className='text-sm text-justify'>{data.firstQuestion}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Input Token Count</p><p className='text-2xl font-semibold text-center'>{data.inputTokenCount}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Output Token Count</p><p className='text-2xl font-semibold text-center'>{data.outputTokenCount}</p></div>
      <div className='w-1/4 flex flex-col items-center '><p className='text-xs font-semibold text-gray-500'>Total Datapoints</p><p className='text-2xl font-semibold text-center'>{data.dataPoints}</p></div>
    </div>
    {showDetails && <motion.div initial={{scaleY:0}} animate={{scaleY:1}} className='mt-4'>
      <h2 className='text-md font-semibold bg-emerald-700 py-2 px-4 mb-2 text-white'>Full Conversation</h2>
      {data.history.map(convo=>(<div>
          {convo.type==="question" && <div className='mb-1'>
            <p className='px-2 text-md font-bold border-l-4 border-gray-800'>Question</p>
            <p className='px-2 text-sm text-gray-600 border-l-4 border-gray-800'>{convo.content}</p>
          </div>}
          {convo.type==="answer" && <div className='mb-1'>
            <p className='px-2 text-md font-bold border-l-4 text-emerald-700 border-emerald-700'>Answer</p>
            <p className='px-2 text-sm text-gray-600 border-l-4 border-emerald-700'>{convo.content}</p>
          </div>}
      </div>))}
    </motion.div>}
  </div>
  )
}
export default function CreateDataset() {
  const navigate=useNavigate()
  const [active,setActive]=useState("details")
  const conversation=[
    {
    firstQuestion:"What is refraction of light?",
    projectTitle:"Physics PCA",
    title:"Magnetism",
    dataPoints:75,
    estimatedCost:0.92,
    history:[
      {
          "type": "question",
          "content": "What is refraction?",
          "model": "llama3:latest",
          "inputTokenCount": null,
          "outputTokenCount": null
      },
      {
          "type": "answer",
          "content": "Refraction is the bending of light or other waves as they pass from one medium to another with a different optical density, such as from air into glass or water. This occurs because the speed of the wave changes as it enters the new medium, causing the wavefront to curve and change direction. Refraction is commonly observed in lenses, prisms, and optical fibers, and plays a crucial role in many areas of physics and engineering, including optics, astronomy, and telecommunications.",
          "model": "llama3:latest",
          "outputTokenCount": 96
      },
      {
          "type": "question",
          "content": "What causes the speed of light to increase when it passes from air into glass?",
          "model": "llama3:latest",
          "inputTokenCount": 168,
          "outputTokenCount": 17
      },
      {
          "type": "answer",
          "content": "The speed of light increases when it passes from air into glass because the density of the glass is higher than that of air. In a medium with a higher optical density, light travels slower due to the increased interaction between the light wave and the medium's molecules. When light enters a denser medium like glass, the interaction becomes stronger, causing the light wave to slow down. However, because glass has a lower refractive index than air, the speed of light actually increases when it passes from air into glass. This is because the change in density causes the light wave to be \"squished\" more efficiently, allowing it to travel faster through the glass.",
          "model": "llama3:latest",
          "inputTokenCount": 173,
          "outputTokenCount": 132
      }]
    },
    {
    firstQuestion:"What is refraction of light?",
    projectTitle:"Physics PCA",
    title:"Magnetism",
    dataPoints:75,
    estimatedCost:0.92,
    history:[
      {
          "type": "question",
          "content": "What is refraction?",
          "model": "llama3:latest",
          "inputTokenCount": null,
          "outputTokenCount": null
      },
      {
          "type": "answer",
          "content": "Refraction is the bending of light or other waves as they pass from one medium to another with a different optical density, such as from air into glass or water. This occurs because the speed of the wave changes as it enters the new medium, causing the wavefront to curve and change direction. Refraction is commonly observed in lenses, prisms, and optical fibers, and plays a crucial role in many areas of physics and engineering, including optics, astronomy, and telecommunications.",
          "model": "llama3:latest",
          "outputTokenCount": 96
      },
      {
          "type": "question",
          "content": "What causes the speed of light to increase when it passes from air into glass?",
          "model": "llama3:latest",
          "inputTokenCount": 168,
          "outputTokenCount": 17
      },
      {
          "type": "answer",
          "content": "The speed of light increases when it passes from air into glass because the density of the glass is higher than that of air. In a medium with a higher optical density, light travels slower due to the increased interaction between the light wave and the medium's molecules. When light enters a denser medium like glass, the interaction becomes stronger, causing the light wave to slow down. However, because glass has a lower refractive index than air, the speed of light actually increases when it passes from air into glass. This is because the change in density causes the light wave to be \"squished\" more efficiently, allowing it to travel faster through the glass.",
          "model": "llama3:latest",
          "inputTokenCount": 173,
          "outputTokenCount": 132
      }]
    }
  ]
  return (
    <div className='w-screen min-h-screen flex flex-col items-center p-6'>
      <div className='fixed top-0 left-0 z-10 shadow-lg border-b w-full h-16 flex justify-start items-center md:p-6 p-4 bg-white'>
          <motion.button onClick={()=>navigate("/")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2  bg-gray-200 rounded hover:bg-gray-300">
              <FaAngleLeft/>
          </motion.button>
          <h2 className='text-md md:text-xl font-semibold ml-4'>Dataset Details</h2>
      </div>
      <div className='w-full flex flex-col items-center mt-16 md:mt-32'>
          <h2 className='text-left text-3xl font-bold '>Manage <span className='text-emerald-700 '>Dataset</span></h2>
          <p className='text-sm font-gray-600'>View, edit and export your dataset</p>
      </div>
      <div className="w-full flex items-center justify-center p-6 ">
        {active==="details" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Details</button>}
        {active!=="details" && <button onClick={()=>setActive("details")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Details</button>}
        {active==="dataset" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Data</button>}
        {active!=="dataset" && <button onClick={()=>setActive("dataset")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Data</button>}
        {active==="export" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Export</button>}
        {active!=="export" && <button onClick={()=>setActive("export")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Export</button>}
        {active==="convert" && <button className='px-4 py-2 font-semibold text-sm  text-white border  border-emerald-700 bg-emerald-700'>Convert</button>}
        {active!=="convert" && <button onClick={()=>setActive("convert")} className='px-4 py-2 font-semibold text-sm border text-emerald-700 border-emerald-700'>Convert</button>}
      </div>
      {active==="details" && <div className='w-full max-w-7xl flex flex-col items-start p-8 bg-gray-100 rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Dataset Details</h2>
        <DatasetDetailsForm data={conversation[0]}/>
      </div>}
      {active==="dataset" && <div className='w-full max-w-7xl flex flex-col items-start p-8 bg-gray-100 rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Datapoints</h2>
        {conversation.map(a=>(<DatasetRow data={a}/>))}
      </div>}
      {active==="export" && <div className='w-full max-w-7xl flex flex-col items-start p-8 bg-gray-100 rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Export Dataset</h2>
        <div className='w-full grid grid-cols-3 gap-4'>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Export as <span className='text-emerald-700'>CSV</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Export as <span className='text-emerald-700'>XLS</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Export as <span className='text-emerald-700'>JSON</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Export as <span className='text-emerald-700'>JSONL</span></h3>
          </motion.div>
        </div>
      </div>}
      {active==="convert" && <div className='w-full max-w-7xl mt-4 flex flex-col items-start p-8 bg-gray-100 rounded shadow-md'>
        <h2 className='text-2xl font-semibold text-emerald-700 mb-4'>Convert Dataset</h2>
        <div className='w-full grid grid-cols-3 gap-4'>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>LLAMA</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>Gemma</span></h3>
          </motion.div>
          <motion.div whileHover={{translateY:"-5px"}} className='flex flex-col items-center p-6 cursor-pointer rounded bg-white border hover:border-emerald-700'>
            <div className='text-4xl text-gray-700'><FaFile/></div>
            <h3 className='text-lg font-semibold'>Convert to <span className='text-emerald-700'>Phi</span></h3>
          </motion.div>
          
        </div>
      </div>}
    </div>
  )
}
