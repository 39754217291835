import React, { useEffect, useRef, useState } from "react";

import UserChatLong from "../../Components/Chatter/UserChatLong";
import AssistantRag from "../../Components/Chatter/AssistantRag";
import AssistantChatLong from "../../Components/Chatter/AssistantChatLong";

// Icons Import
import { GrChat } from "react-icons/gr";
import { RiUploadCloud2Line } from "react-icons/ri";
import { FaAngleLeft, FaArrowLeft, FaChalkboardTeacher } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { VscServerProcess } from "react-icons/vsc";
import {motion} from 'framer-motion'
// PDF Renderer Elements Import
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; //npm install pdfjs-dist@3.4.120

// API Imports
import axios from 'axios'
import { BACKEND_ENDPOINT } from "../../Data/APIEndpoints";
import { useNavigate } from "react-router-dom";

export default function ChatTeach() {
  const navigate=useNavigate()
  const bottomRefStudent=useRef()
  const bottomRefTeacher=useRef()
  const [width, setWidth] = useState()
  const [height,setHeight]=useState()

  const [uploadedFile,setUploadedFile]=useState(null)
  const [pdfUrl,setPdfUrl]=useState(null)

  const [showTeacherDrawer, setShowTeacherDrawer] = useState(false);
  const [showStudentDrawer, setShowStudentDrawer] = useState(false);

  const [uploadSuccess,setUploadSuccess]=useState(false)
  const [ingested,setIngested]=useState(false)

  const [teacherConversation,setTeacherConversation]=useState([
    {   
      role:"assistant",
      content:"Hello there I can answer questions from the book"
    }
  ])

  const [currentTecherChat,setCurrentTeacherChat]=useState(null)
  const [generatingTeacherChat,setGeneratingTeacherChat]=useState(false)
  const [studentConversation,setStudentConversation]=useState([
    {   
      role:"system",
      content:"You are an agent that helps users learn things. The user may ask you questions or tell you to explain certain things. Try to answer appropriately. If you give empty answers, you will be penalized 100 dollars. If you answer in markdown you will get 100 dollar reward"
    },
    {   
      role:"assistant",
      content:"Hello there I can help you answers questions from the book that you have uploaded"
    }
  ])

  const [currentStudentChat,setCurrentStudentChat]=useState(null)
  const [generatingStudentChat,setGeneratingStudentChat]=useState(false)
  const [currentStudentQuestion,setCurrentStudentQuestion]=useState(null)
  

  const sendDocument=async()=>{
    if(uploadedFile==null){
      alert("Please select a PDF file first!")
      return
    }
    const formData=new FormData()
    formData.append('files[]',uploadedFile)
    //console.log(formData)
    const res=await axios.post(BACKEND_ENDPOINT+"/file-upload",formData)
    //console.log(res.data.status)
    if(res.data.status=="success"){
      setUploadSuccess(true)
      alert("Your file has been sent to model")
    }else{
      alert("Your file was not uploaded. Try with a smaller file please.")
    }
  }
  const ingestDocuments=async()=>{
    if(!uploadSuccess){
      alert("Upload your PDF to the model first!")
      return
    }
    const res=await axios.get(BACKEND_ENDPOINT+"/ingest")
    //console.log(res.data)
    if(res.data.status=="success"){
      setIngested(true)
      alert("Ingestion Complete! You can chat now!")
    }else{
      alert("Your ingestion was not complete. Please Try again!")
    }
  }
  useEffect(()=>{
    if(bottomRefTeacher.current){
      bottomRefTeacher.current.scrollIntoView()
    }
  },[teacherConversation])
  useEffect(()=>{
    if(bottomRefStudent.current){
      bottomRefStudent.current.scrollIntoView()
    }
  },[studentConversation])
  const sendRAGQuery=async()=>{
    if(!ingested){
      alert("You need to upload and ingest your document before asking questions")
      return
    }
    
    setGeneratingTeacherChat(true)
    const userConvBody={
      role:"user",
      content:currentTecherChat
    }
    setTeacherConversation((conversation)=>[...conversation,userConvBody])
    const res=await axios.post(BACKEND_ENDPOINT+"/rag",{
      "query":currentTecherChat
    })
    setCurrentTeacherChat("")
    console.log(res.data)
    const assistantConv={
      role:"assistant-rag",
      content:res.data
    }
    
    setTeacherConversation((conversation)=>[...conversation,assistantConv])
    setGeneratingTeacherChat(false)
  }
  const questionRAG=async()=>{
    if(!ingested){
      alert("You need to upload and ingest your document before asking questions")
      return
    }
    setGeneratingStudentChat(true)
    const res=await axios.get(BACKEND_ENDPOINT+"/rag-question")
    console.log(res.data)
    const assistantConv={
      role:"assistant",
      content:res.data.result
    }
    setCurrentStudentQuestion(res.data.result)
    setStudentConversation((conversation)=>[...conversation,assistantConv])
    setGeneratingStudentChat(false)
  }
  const verifyRAG=async(ques)=>{
    if(!ingested){
      alert("You need to upload and ingest your document before asking questions")
      return
    }
    const userConvBody={
      role:"user",
      content:currentStudentChat
    }
    setStudentConversation((conversation)=>[...conversation,userConvBody])
    setGeneratingStudentChat(true)
    const res=await axios.post(BACKEND_ENDPOINT+"/rag-verify",{
      "question":ques,
      "answer":currentStudentChat
    })
    setCurrentStudentChat("")
    console.log(res.data)
    const assistantConv={
      role:"assistant",
      content:res.data.result
    }
    
    setStudentConversation((conversation)=>[...conversation,assistantConv])
    setGeneratingStudentChat(false)
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight)
  }, []);
  return (
    <div>
      {/**CUSTOM NAVBAR */}
      <div className="flex z-10 w-screen h-12 bg-blue-50 dark:bg-blue-950 fixed top-0" style={{ alignItems: "center" }}>
          <motion.button onClick={()=>navigate("/student-options")} whileHover={{scale:1.05}} whileTap={{scale:0.95}} className="p-2 ml-4 bg-gray-200 rounded hover:bg-gray-300">
            <FaAngleLeft/>
          </motion.button>
          <img alt="logo" width="25px" height="25px" className="mr-2 ml-6" src="https://i.postimg.cc/L8T1V5pD/image.png" onClick={() => navigator("/")}/>
          <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Learn By Teaching</h2>
      </div>
      
      <button type="button" onClick={sendDocument} class="z-20 fixed flex items-center px-6 right-4 top-24 bg-primary-500 text-white shadow-lg hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><IoCloudUploadOutline size={24} className="mr-2"/>Upload Doc</button>
      <button type="button" onClick={ingestDocuments} class="z-20 fixed flex items-center px-6 right-4 top-36 bg-primary-500 text-white shadow-lg hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><VscServerProcess size={24} className="mr-2"/>Ingest Doc</button>
      <div className="w-full h-screen flex flex-col items-center justify-center dark:bg-slate-900">
        {/**FILE UPLOADER */}
        {uploadedFile==null && <div class="flex items-center justify-center h-full md:w-1/3">
          <label
            for="dropzone-file"
            class="flex flex-col items-center justify-center w-full h-2/3 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-blue-50 dark:hover:bg-blue-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600"
          >
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <RiUploadCloud2Line
                size={32}
                className="text-blue-500 dark:text-blue-400"
              />
              <p class="mb-2 text-sm text-blue-500 dark:text-blue-400">
                <span class="font-semibold">Click to upload</span> or drag and
                drop
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">
                PDF, PPTX, CSV or TXT (MAX. 2MB)
              </p>
            </div>
            <input id="dropzone-file" type="file" class="hidden" value={uploadedFile} onChange={(e)=>{
              setUploadedFile(e.target.files[0])
              setPdfUrl(URL.createObjectURL(e.target.files[0]))
            }}/>
          </label>
        </div>}
        {pdfUrl!=null &&<div className="w-2/5 max-h-[300px] md:max-h-[700px] overflow-y-scroll">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfUrl} />
            </Worker> 
          </div>}
        {/**STUDENT BOT */}
        <div
          class={`fixed w-1/4 bg-white border-t border-x border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform left-24  ${!showStudentDrawer? "-bottom-[640px]":"bottom-0"}`}
        >
          <div
            class="p-4 w-full rounded-t-lg cursor-pointer bg-blue-400 hover:bg-blue-400"
            onClick={() => setShowStudentDrawer(!showStudentDrawer)}
          >
            <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-100 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
            <h5 class="inline-flex items-center text-base text-gray-100 dark:text-gray-400 font-medium">
              <FaChalkboardTeacher size={24} className="mx-2" /> Student Bot
            </h5>
          </div>

          <div class={`relative grid  grid-cols-1 h-[640px] gap-4 py-4 pl-4 lg:grid-cols-1`}>
              <div className=" flex pb-20 flex-col items-start h-full overflow-y-scroll overflow-x-hidden">
                <button  type="submit" class="text-white mb-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={questionRAG}>Generate New Question</button>
                
                {studentConversation.map(a=>{
                  if(a.role=="assistant"){
                      return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/tJnP4mvq/18.png"/>)
                  }
                  if(a.role=="assistant-rag"){
                      return (<AssistantRag data={a.content} avatar="https://i.postimg.cc/tJnP4mvq/18.png"/>)
                  }
                  else if(a.role=="user"){
                      return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                  }
                  })}
                  {generatingStudentChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/tJnP4mvq/18.png"/>}
                  <div ref={bottomRefStudent}></div>
              </div>
            <div className="absolute left-0 -bottom-1 bg-gray-200 dark:bg-gray-950 w-full rounded-b-lg">
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <GrChat className="dark:text-white"/>
                </div>
                <input type="text" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentStudentChat} onChange={e=>setCurrentStudentChat(e.target.value)}/>
                <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"  onKeyDown={(e)=>{
                        if(e.key==="Enter"){
                          e.preventDefault()
                          if(currentStudentQuestion!=null){
                            verifyRAG(currentStudentQuestion)
                          }else{
                            alert("Generate a student question first!")
                          }
                        }
                      }}  onClick={()=>{
                  if(currentStudentQuestion!=null){
                    verifyRAG(currentStudentQuestion)
                  }else{
                    alert("Generate a student question first!")
                  }
                }}>Send</button>
              </div>
            </div>

        </div>
        </div>
        {/**TEACHER BOT */}
        <div
          class={`fixed w-1/4 bg-white border-t border-x border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-gray-800 transition-transform right-24  ${!showTeacherDrawer? "-bottom-[640px]":"bottom-0"}`}
        >
          <div
            class="p-4 w-full rounded-t-lg bg-primary-500 cursor-pointer hover:bg-primary-500 "
            onClick={() => setShowTeacherDrawer(!showTeacherDrawer)}
          >
            <span class="absolute w-8 h-1 -translate-x-1/2 bg-gray-100 rounded-lg top-3 left-1/2 dark:bg-gray-600"></span>
            <h5 class="inline-flex items-center text-base text-gray-100 dark:text-gray-400 font-medium">
              <FaChalkboardTeacher size={24} className="mx-2" /> Teacher Bot
            </h5>
          </div>

          <div class={`relative grid  grid-cols-1 h-[640px] gap-4 py-4 pl-4 lg:grid-cols-1`}>
          <div className=" flex flex-col items-start pb-20 h-full overflow-y-scroll overflow-x-hidden">
            
            {/*<AssistantChatLong full  text={"Hello there I can answer questions from the book"} avatar="https://i.postimg.cc/tJnP4mvq/18.png"/>
            {userAnsTeacher && <UserChatLong full  text={userTeacherVal} avatar="https://i.postimg.cc/B6kppFdv/13.png"/>}
        {teacherAnswer && <AssistantChatLong full  text={"Gobuster is a tool that does bruteforching in various situations"} avatar="https://i.postimg.cc/tJnP4mvq/18.png"/>*/}
          {teacherConversation.map(a=>{
            if(a.role=="assistant"){
                return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
            }
            if(a.role=="assistant-rag"){
                return (<AssistantRag data={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
            }
            else if(a.role=="user"){
                return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
            }
            })}
            {generatingTeacherChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
            <div ref={bottomRefTeacher}></div>
          </div>
          <div className="absolute -bottom-1 bg-gray-200 dark:bg-gray-950 w-full rounded-b-lg">
          <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <GrChat className="dark:text-white"/>
          </div>
          <input onKeyDown={(e)=>{
                  if(e.key==="Enter"){
                    e.preventDefault()
                    sendRAGQuery()
                  }
                }} type="text" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your chat" value={currentTecherChat} onChange={e=>setCurrentTeacherChat(e.target.value)}/>
          <button  type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={sendRAGQuery}>Send</button>
          </div>
      </div>

          </div>
        </div>
      </div>
    </div>
  );
}
