import { Checkbox } from 'flowbite-react'
import React from 'react'
import ProfileNotesCard from './ProfileNotesCard'

export default function MyNotes({openNote}) {
  return (
    <div className='my-4'>
        <div className="bg-indigo-700 text-white px-6 py-4 border rounded-t-lg">
            <h1 className="text-3xl font-bold">My Notes</h1>
            <p className="text-indigo-200">All the notes taken on StudyGazelle</p>
        </div>
        <div className=" p-6 border border-t-0 rounded-b-lg">
            <ProfileNotesCard openNote={openNote}/>
            <ProfileNotesCard openNote={openNote}/>
            <ProfileNotesCard openNote={openNote}/>
        </div>
    </div>
  )
}
