import React,{useRef} from 'react'
import {motion} from 'framer-motion'
import {GrUser} from 'react-icons/gr'
import {BiSave} from 'react-icons/bi'
import {FaMinus} from 'react-icons/fa'
import MarkdownEditor from '@uiw/react-markdown-editor';
export default function MyNotes({groupNotesActive,minimizeMyNotes,myNote,setMyNote}) {
    const editorRef=useRef()
    return (
    <div className={groupNotesActive ? `relative w-1/2 h-full border border-gray-200 bg-orange-400 overflow-y-scroll no-scrollbar`:`relative w-full h-full border border-gray-200 bg-orange-400 overflow-y-scroll no-scrollbar`}>
        <motion.button onClick={minimizeMyNotes} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='z-10 absolute top-2 right-2 p-1 rounded-full shadow-md border border-gray-100 bg-white cursor-pointer text-xs hover:bg-gray-50'><FaMinus /></motion.button>
        <motion.button onClick={()=>alert(myNote)} whileHover={{scale:1.1}} whileTap={{scale:0.9}} className='z-10 absolute top-2 right-8 p-1 rounded-full shadow-md border border-gray-100 bg-white cursor-pointer text-xs hover:bg-gray-50'><BiSave /></motion.button>
        <div className='w-full h-full flex flex-col'>
        <p className='flex items-center p-2 border border-gray-200 text-white text-sm font-semibold px-4'><GrUser className='mr-2'/>My Notes</p>
        <MarkdownEditor
            ref={editorRef}
            className='w-full h-full'
            value={myNote}
            toolbarBottom={true}
            onChange={(value, viewUpdate) => {
                setMyNote(value)
            }}
            />
        </div>
    </div>
  )
}
