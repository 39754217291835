import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; //npm install pdfjs-dist@3.4.120
import { CgAdd } from 'react-icons/cg';
import { GrUpload } from 'react-icons/gr';

function CCBooksModal({ open, onClose }) {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [uploadNewPdf, setUploadNewPdf] = useState(true);
  const [tabs, setTabs] = useState([]);

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files).filter(
      (file) => file.type === 'application/pdf'
    );
    const pdfs = files.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));
    setTabs((prevTabs) => [pdfs.map((pdf) => pdf.name)]);
    setPdfFiles((prevPdfs) => [...prevPdfs, ...pdfs]);
    setUploadNewPdf(false);
  };

  const handleTabChange = (index) => {
    setCurrentTab(index);
  };

  const resetUploader = () => {
    setUploadNewPdf(true);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="z-20 fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center"
          onClick={onClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="bg-white rounded-lg max-w-7xl w-full"
            onClick={(e) => e.stopPropagation()}
          >
            {uploadNewPdf && (
            <div className="flex flex-col items-center">
              <h1 className="w-full rounded-t-md bg-primary-500 p-4 text-2xl text-center font-bold ">Upload New Book</h1>
              <div className='p-12 w-full'>
                <motion.label
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed border-primary-500 bg-primary-50 hover:bg-primary-100 rounded cursor-pointer"
                >
                    <GrUpload className="text-4xl" />
                    <p className='py-3 text-lg font-semibold text-gray-800'>Select PDF Files</p>
                    <input
                    type="file"
                    accept="application/pdf"
                    multiple
                    onChange={handleFileUpload}
                    className="hidden"
                    />
              </motion.label>
              </div>
            </div>
            )}
            {!uploadNewPdf && pdfFiles.length > 0 && (
              <div className='rounded-t-md'>
                    <h1 className="rounded-t-md bg-primary-500 p-4 text-2xl text-center font-bold mb-4">Classroom Books</h1>
                    <div className="flex justify-center space-x-2 space-y-2 flex-wrap mb-4">
                        {pdfFiles.map((file, index) => (
                            <button
                            key={index}
                            onClick={() => handleTabChange(index)}
                            className={`px-4 py-2 rounded-md ${
                                currentTab === index
                                ? 'border-b-2 bg-primary-500 font-bold'
                                : 'border border-primary-500 hover:bg-primary-50'
                            }`}
                            >
                            {file.name}
                            </button>
                        ))}
                        <button
                            onClick={resetUploader}
                            className="bg-primary-500 hover:bg-primary-600 text-white px-4 py-2 rounded"
                            >
                            <CgAdd/>
                        </button>
                    </div>
                    <div className="mb-4 h-[80vh] w-full ">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer fileUrl={pdfFiles[currentTab].url} />
                        </Worker> 

                    </div>
                
                </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CCBooksModal;