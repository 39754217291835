import React,{useRef,useEffect,useState} from 'react'
import AssistantChatLong from '../Chatter/AssistantChatLong'
import UserChatLong from '../Chatter/UserChatLong'
import { conversationRequest } from '../../Models/ConversationAgent'
import { IoSendSharp } from "react-icons/io5";
import { BiBot } from 'react-icons/bi';
export default function MyChatter() {
    const bottomRef=useRef()
    const [generatingChat,setGeneratingChat]=useState(false)
    const [model,setModel]=useState("llama3.1")
    const [currentUserChat,setCurrentUserChat]=useState(null)
    const [conversation,setConversation]=useState([
        {   
          role:"system",
          content:"You are an agent that helps users learn things. The user may ask you questions or tell you to explain certain things. Try to answer appropriately. If you give empty answers, you will be penalized 100 dollars. If you answer in markdown you will get 100 dollar reward"
        },
        {   
          role:"assistant",
          content:"Hello there I'm your assistant here to help you with anythng that you need. Please feel free to ask me any general questions about anything that you might want to know. I am here to assist you in any way that I can.I understand that I cannot give empty answers, so I will always make sure to give you a helpful response or redirect you to a source that can help you.Let me know how I can assist you today!"
        }
    ])
    const generateAnswer=async(conv)=>{
        const agentConvBody=await conversationRequest(conv,model)
        if(agentConvBody==null){
          alert("Choose A model first!")
          return
        }
        setConversation((conversation)=>[...conversation,agentConvBody])
        setGeneratingChat(false)
      }
    const createConversation=async()=>{
        const userConvBody={
            role:"user",
            content:currentUserChat
        }
        setConversation((conversation)=>[...conversation,userConvBody])
        const conv=[...conversation,userConvBody]
        generateAnswer(conv)
        setGeneratingChat(true)
        setCurrentUserChat("")

    }
    useEffect(()=>{
        if(bottomRef.current){
          bottomRef.current.scrollIntoView()
        }
      },[conversation])
  return (
    <div class="relative w-full h-full bg-white border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ">
          {/**CHATS */}
          <div className="absolute pb-20 top-0 flex p-6 flex-col w-full h-full overflow-y-scroll no-scrollbar">
              {conversation.map(a=>{
                  if(a.role=="assistant"){
                      return (<AssistantChatLong text={a.content} avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>)
                  }
                  else if(a.role=="user"){
                    return (<UserChatLong text={a.content} avatar="https://i.postimg.cc/8kft5y6f/17.png"/>)
                  }
                  
              })}
              {generatingChat && <AssistantChatLong text="Hmmmmmm.......Let me think....." avatar="https://i.postimg.cc/c4qT8QR9/12.png"/>}
              <div ref={bottomRef}></div>
          </div>
          {/**INPUT */}
          <div className="absolute flex justify-between bottom-0 bg-gray-200 dark:bg-gray-950 w-full p-2">
                <textarea onKeyDown={(e)=>{
                  if(e.key==="Enter"){
                    e.preventDefault()
                    createConversation()
                  }
                }} type="text" rows={1} class=" block w-full mr-1 p-4 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500" placeholder="Enter your chat" value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
                <div className="flex">
                  <button onClick={createConversation} class="h-full ml-1 text-white  bg-red-400 hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium text-2xl p-4 rounded dark:bg-red-400 dark:hover:bg-red-500 dark:focus:ring-red-600"><BiBot /></button>
                </div>
              </div>
        </div>
  )
}
